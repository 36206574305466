import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import * as Constants from 'assets/constants/constants';
import * as Utils from 'assets/js/Utils';
import DialogHistory from 'components/DialogHistory/DialogHistory';
import TitleBar from 'components/TitleBar/TitleBar';
import DialogWarning from 'components/UI/DialogBasics/DialogWarning';
import Pagination from 'components/UI/Pagination/Pagination';
import Search from 'components/UI/Search/Search';
import SearchFilter from 'components/UI/SearchFilter/SearchFilter';
import RecommendationsRow from 'components/UI/TableList/RecommendationsRow/RecommendationsRow';
import TableList from 'components/UI/TableList/TableList';
import useError from 'hooks/useError';
import usePagination from 'hooks/usePagination';
import communicationService from 'services/communicationService';
import documentationService from 'services/documentationService';
import recommendationService from 'services/recommendationService';
import titleService from 'services/titleService';
import userService from 'services/userService';

import styles from './RecommendationsList.module.scss';

const RecommendationsList = (props) => {
  const { t, i18n } = useTranslation();
  const { handleServiceError } = useError();
  const header = {
    name: t('tasks.name'),
    locations: t('recommendations.table.locations'),
    recommendedOn: t('recommendations.table.recommendedOn'),
    recommendedBy: t('recommendations.table.recommendedBy'),
    status: t('recommendations.table.status'),
    feedback: t('recommendations.table.feedback'),
    gaplog: t('recommendations.table.gaplog'),
    report: t('recommendations.table.report'),
    history: t('history.title'),
    update: t('recommendations.table.update'),
  };

  const filtersList = {
    location: {
      filterData: [],
      type: Constants.LOCATION,
    },
    date: {
      filterData: '',
      type: Constants.DATE,
    },
    recommendedBy: {
      filterData: [],
      type: Constants.USER,
    },
    status: {
      filterData: [Constants.RECOMMENDED_ID, Constants.FEEDBACK_GIVEN_ID, Constants.PUBLISHED_ID],
      type: Constants.CHECKBOX,
    },
    feedbackType: {
      filterData: [Constants.NOT_RELEVANT_ID, Constants.NOT_ACCEPTED_ID, Constants.ACCEPTED_ID, Constants.PUBLISHED_ID],
      type: Constants.CHECKBOX,
    },
    gapLogCategory: {
      filterData: [Constants.CATEGORY1_ID, Constants.CATEGORY2_ID, Constants.CATEGORY3_ID],
      type: Constants.CHECKBOX,
    },
  };

  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [dataHistory, setDataHistory] = useState(null);
  const [locations, setLocations] = useState([]);
  const [isEmptyDialogVisible, setIsEmptyDialogVisible] = useState();
  const id = Utils.cloneObject(props.match.params.id);
  const processNumber = props.location.state?.processNumber;
  const language = i18n.language.toUpperCase();

  const getUser = (user) => {
    let lastUser = '';

    if (user) {
      lastUser = !user.departments
        ? user.commonName
        : user.departments.reduce((acc, department, i, vector) => {
            if (i === 0 && vector.length === 1) return `${acc}, (${department})`;
            if (i === 0) return `${acc}, (${department}, `;
            if (i === vector.length - 1) return `${acc}${department})`;

            return `${acc}${department}, `;
          }, user.commonName);
    }

    return lastUser;
  };

  const mapRecommendations = useCallback(
    (recommendationsData) =>
      recommendationsData.map((elem) => ({
        name: JSON.parse(elem.name)[language],
        processNumber: elem.processNumber,
        locations: elem.locations.map((location) => {
          const lastUser = location.recommendedBy ? getUser(location.recommendedBy) : '-';
          return {
            id: location.id,
            locationCode: location.location,
            location: locations.find((loc) => loc.code === location.location).displayName,
            isPublished: location.status === Constants.PUBLISHED_STATUS,
            recommendedOn: Utils.getFormattedDate(location.recommendedOn),
            recommendedBy: lastUser,
            status: t(`recommendations.status.${location.status}`),
            feedback: t(`recommendations.feedback.${location.feedback}`),
            gaplog: location.gapLogCategory
              ? t(`attributes.${Constants.GAP_LOG_CATEGORY}.options.${location.gapLogCategory}`)
              : '-',
            update: false,
            version: location.versionPlant || 'last',
          };
        }),
      })),
    [language, locations, t],
  );

  const getResults = useCallback(
    (params) => {
      const newParams = { ...params };
      delete newParams.search;
      return recommendationService
        .getRecommendations({ ...newParams, epcName: params.search })
        .then((res) => {
          const map = mapRecommendations(res.data.recommendations);
          return { results: map, numberOfPages: res.data.numberOfPages };
        })
        .catch((err) => recommendationService.handleServiceError(err));
    },
    [mapRecommendations],
  );

  const pagination = usePagination({ getResults: !!locations.length && getResults });
  const {
    handleFiltersChange,
    handleSearchChange,
    filters,
    resetFilter,
    resetFilters,
    page,
    results,
    setPage,
    sorting,
    sortTable,
    totalPages,
  } = pagination;

  useEffect(() => {
    if (!results || isEmptyDialogVisible !== undefined) return;
    setIsEmptyDialogVisible(results.length === 0);
  }, [results, isEmptyDialogVisible]);

  const getPlants = () => {
    communicationService
      .getLocations()
      .then((response) => {
        const activeLocations = response.data.map((elem) => ({
          code: elem.code,
          displayName: `${elem.name} - ${elem.location}, ${elem.country}${elem.daimlerCode ? ` (${elem.daimlerCode})` : ''}`,
        }));
        setLocations(activeLocations);
      })
      .catch((err) => {
        communicationService.handleServiceError(err);
      });
  };

  const getFilteredLocations = useCallback((searchValue) => {
    return communicationService
      .getFilteredLocations(searchValue)
      .then((response) =>
        response.data.map(({ code, location, country }) => {
          return { label: `${location} (${country})`, value: code };
        }),
      )
      .catch(handleServiceError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsers = useCallback((searchValue) => {
    return userService
      .getUsersByRole(searchValue)
      .then((response) =>
        response.data.results.map(({ code, commonName }) => {
          return { label: `${commonName} (${code})`, value: code };
        }),
      )
      .catch(handleServiceError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    titleService.updatePageTitle(t('processRecommendations'));
    getPlants();
  }, [t]);

  const openHistoryDialog = (recommendation) => {
    setShowHistoryDialog(true);

    recommendationService
      .getRecommendationVersions(recommendation.locationCode, recommendation.processNumber)
      .then((res) => {
        let historyList = [];

        historyList = res.data.map((elem) => ({
          name: JSON.parse(elem.processName)[language].TITLE,
          processNumber: elem.processNumber,
          date: Utils.getFormattedDate(elem.date),
          recommendedBy: getUser(elem.recommendedBy) || '-',
          status: t(`recommendations.status.${elem.status}`),
          gaplog: elem.gapLog ? t(`attributes.${Constants.GAP_LOG_CATEGORY}.options.${elem.gapLog}`) : '-',
          version: elem.version,
          versionPlant: elem.versionPlant,
          locationCode: recommendation.locationCode,
        }));

        setDataHistory(historyList);
      })
      .catch((err) => {
        recommendationService.handleServiceError(err);
      });
  };

  const switchUpdate = () => {
    // TODO
  };

  const handleDownloadClick = (element) => {
    documentationService
      .getDocument(element.processNumber, element.version, language, Constants.EXCHANGE, 'pdf', element.locationCode)
      .then((res) => {
        const fileName = `${element.name}.pdf`;
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        Utils.downloadFile(url, fileName, res.headers['content-disposition']);
      })
      .catch((err) => {
        documentationService.handleServiceError(err);
      });
  };

  const filtersInfo = [
    {
      text: 'nameAttributes.RECOMMENDED_LOCATIONS',
      filterType: Constants.LOCATION,
      nameFilter: 'location',
      isOpen: false,
      isSelected: false,
      getResults: getFilteredLocations,
    },
    {
      text: 'recommendations.table.recommendedOn',
      filterType: Constants.DATE,
      nameFilter: 'date',
      isOpen: false,
      isSelected: false,
    },
    {
      text: 'recommendations.table.recommendedBy',
      filterType: Constants.USER,
      nameFilter: 'recommendedBy',
      isOpen: false,
      isSelected: false,
      getResults: getUsers,
    },
    {
      text: 'recommendations.table.status',
      filterType: Constants.CHECKBOX,
      nameFilter: 'status',
      isOpen: false,
      isSelected: false,
      options: {
        [Constants.RECOMMENDED]: {
          label: t('recommendations.status.RECOMMENDED'),
          id: Constants.RECOMMENDED_ID,
          checked: false,
          keyName: Constants.RECOMMENDED,
        },
        [Constants.FEEDBACK_GIVEN]: {
          label: t('recommendations.status.FEEDBACK_GIVEN'),
          id: Constants.FEEDBACK_GIVEN_ID,
          checked: false,
          keyName: Constants.FEEDBACK_GIVEN,
        },
        [Constants.PUBLISHED]: {
          label: t('recommendations.status.PUBLISHED'),
          id: Constants.PUBLISHED_ID,
          checked: false,
          keyName: Constants.PUBLISHED_STATUS,
        },
      },
    },
    {
      text: 'recommendations.table.feedback',
      filterType: Constants.CHECKBOX,
      nameFilter: 'feedbackType',
      isOpen: false,
      isSelected: false,
      options: {
        [Constants.NOT_RELEVANT]: {
          label: t('recommendations.feedback.IRRELEVANT'),
          id: Constants.NOT_RELEVANT_ID,
          checked: false,
          keyName: Constants.NOT_RELEVANT,
        },
        [Constants.NOT_ACCEPTED]: {
          label: t('recommendations.feedback.NOT_ACCEPTED'),
          id: Constants.NOT_ACCEPTED_ID,
          checked: false,
          keyName: Constants.NOT_ACCEPTED,
        },
        [Constants.ACCEPTED]: {
          label: t('recommendations.feedback.ACCEPTED'),
          id: Constants.ACCEPTED_ID,
          checked: false,
          keyName: Constants.ACCEPTED,
        },
        [Constants.PUBLISHED]: {
          label: t('recommendations.feedback.PUBLISHED'),
          id: Constants.PUBLISHED_ID,
          checked: false,
          keyName: Constants.PUBLISHED_STATUS,
        },
      },
    },
    {
      text: 'recommendations.table.gaplog',
      filterType: Constants.CHECKBOX,
      nameFilter: 'gapLogCategory',
      isOpen: false,
      isSelected: false,
      options: {
        [Constants.CATEGORY1]: {
          label: t('attributes.GAP_LOG_CATEGORY.options.CATEGORY1'),
          id: Constants.CATEGORY1_ID,
          checked: false,
          keyName: Constants.CATEGORY1,
        },
        [Constants.CATEGORY2]: {
          label: t('attributes.GAP_LOG_CATEGORY.options.CATEGORY2'),
          id: Constants.CATEGORY2_ID,
          checked: false,
          keyName: Constants.CATEGORY2,
        },
        [Constants.CATEGORY3]: {
          label: t('attributes.GAP_LOG_CATEGORY.options.CATEGORY3'),
          id: Constants.CATEGORY3_ID,
          checked: false,
          keyName: Constants.CATEGORY3,
        },
      },
    },
  ];

  return (
    <>
      <TitleBar />
      <div className={styles.Content}>
        {!id && (
          <div>
            <div className={styles.SearchSection}>
              <Search isAsync searching={handleSearchChange} />
            </div>
            <div className={styles.Filters}>
              {filtersInfo.map((f) => (
                <SearchFilter
                  filter={f}
                  filtersInfo={filtersInfo}
                  filtersList={filtersList}
                  getResults={getResults}
                  handleFiltersChange={handleFiltersChange}
                  key={f.text}
                  locations={locations}
                  options={f.options}
                  paginationFilters={filters}
                  resetFilter={resetFilter}
                  value={filters[f.nameFilter]?.split(',')}
                />
              ))}
              <div className={styles.ClearButton} onClick={() => resetFilters()}>
                {t('clearAll').toUpperCase()}
              </div>
            </div>
          </div>
        )}

        <div className={styles.TableContent}>
          <TableList
            direction={sorting.order}
            fieldToOrder={sorting.filter}
            handleDownloadClick={handleDownloadClick}
            header={header}
            list={results}
            recommendationsTable
            RowComponent={RecommendationsRow}
            showHistoryDialog={openHistoryDialog}
            sortTable={sortTable}
            switchUpdate={() => switchUpdate()}
          />
        </div>
      </div>
      {results?.length > 0 && totalPages > 1 && (
        <div className={styles.PagesSection}>
          <Pagination page={page} pageClick={setPage} totalPages={totalPages} />
        </div>
      )}
      <div id="dialog">
        {showHistoryDialog && (
          <DialogHistory
            close={() => {
              setShowHistoryDialog(false);
              setDataHistory(null);
            }}
            dataHistory={dataHistory}
            handleDownloadClick={handleDownloadClick}
            isRecommendationHistory
            language={language}
            processNumber={processNumber}
            refAssignment={() => {}}
          />
        )}
        {isEmptyDialogVisible && (
          <DialogWarning handleConfirm={() => setIsEmptyDialogVisible(false)}>
            {t('recommendations.isEmptyMessage')}
          </DialogWarning>
        )}
      </div>
    </>
  );
};

export default RecommendationsList;
