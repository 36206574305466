import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getFormattedDate } from 'assets/js/Utils';

import styles from './DepartmentDiagramRow.module.scss';

const DepartmentDiagramRow = ({ isHeader, element, sortTable, fieldToOrder, direction, extraClick }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const getSortedClass = (field) => (fieldToOrder === field ? styles[direction] : '');
  const { i18n } = useTranslation();
  const [fav, setFav] = useState(element.favorite);

  if (isHeader) {
    return (
      <tr className={`${styles.Header} ${styles.ListItem}`}>
        <th className={`${styles.NameDeparment} ${getSortedClass('PROCESS_NAME')}`} onClick={() => sortTable('PROCESS_NAME')}>
          {element.nameMap}
        </th>
        <th className={`${styles.Author} ${getSortedClass('AUTHOR')}`} onClick={() => sortTable('AUTHOR')}>
          {element.author}
        </th>
        <th className={`${styles.Created} ${getSortedClass('VALID_FROM')}`} onClick={() => sortTable('VALID_FROM')}>
          {element.creationDate}
        </th>
        <th className={`${styles.Type} ${styles.NonSortable}`}>{element.type}</th>
        <th className={styles.FavIcon}>{}</th>
      </tr>
    );
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  const handleLinkClick = (id) => {
    history.push(`/published/${id}`);
  };

  return (
    <tr className={styles.ListItem}>
      <td className={`${styles.NameDeparment} ${styles.Link}`} onClick={() => handleLinkClick(element.id)}>
        {JSON.parse(element.nameMap)[i18n.language.toUpperCase()].PROCESS_NAME}
      </td>
      <td className={styles.Author}>{element.author}</td>
      <td className={styles.Created}>{getFormattedDate(element.creationDate)}</td>
      <td className={styles.Type}>{t(element.type)}</td>
      <td className={styles.FavIcon} onClick={(e) => extraClick(element, setFav, fav, e)}>
        {fav ? <i className="di icon-stern-favorit" /> : <i className="di icon-stern-favorit-konturlinie" />}
      </td>
    </tr>
  );
};

export default DepartmentDiagramRow;
