import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  GET_CATALOG_OBJECTS_BY_TYPE_RESULTS,
  GET_CATALOG_OBJECTS_BY_TYPE_TOTAL_RESULTS,
  IT_SYSTEM,
  IT_SYSTEM_ID,
  IT_SYSTEM_SWIMLANE_ID,
  IT_SYSTEM_SWIMLANE,
} from 'assets/constants/constants';
import CatalogObjectTable from 'components/CatalogObjectTable/CatalogObjectTable';
import Page from 'components/Page/Page';
import TitleBar from 'components/TitleBar/TitleBar';
import CatalogObjectRow from 'components/UI/TableList/CatalogObjectRow/CatalogObjectRow';
import useEnvironment from 'hooks/useEnvironment';
import usePagination from 'hooks/usePagination';
import objectCatalogService from 'services/objectCatalogService';
import titleService from 'services/titleService';

const ITSystems = () => {
  const { t } = useTranslation();
  const checkEnvironment = useEnvironment();

  const isSwimlane = checkEnvironment('swimlane');

  const getResults = useCallback(
    (params) =>
      objectCatalogService
        .getCatalogObjectsByType(isSwimlane ? IT_SYSTEM_SWIMLANE_ID : IT_SYSTEM_ID, params)
        .then((response) => ({
          numberOfPages: response.data.numberOfPages,
          results: response.data[GET_CATALOG_OBJECTS_BY_TYPE_RESULTS],
          totalResults: response.data[GET_CATALOG_OBJECTS_BY_TYPE_TOTAL_RESULTS],
        })),
    [isSwimlane],
  );

  const pagination = usePagination({ getResults });

  const tableHeader = {
    area: t('nameAttributes.tables.AREA'),
    created: t('nameAttributes.tables.CREATED'),
    description: t('nameAttributes.OBJECT_DESCRIPTION'),
    name: t('nameAttributes.OBJECT_NAME'),
    usedIn: t('nameAttributes.tables.USED_IN'),
  };

  useEffect(() => {
    titleService.updatePageTitle(isSwimlane ? t('IT_SYSTEM_SWIMLANE') : t('itSystems'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page>
      <CatalogObjectTable
        header={tableHeader}
        labelX={isSwimlane ? t('IT_SYSTEM_SWIMLANE') : t('itSystems')}
        objectType={isSwimlane ? IT_SYSTEM_SWIMLANE : IT_SYSTEM}
        pagination={pagination}
        rowComponent={CatalogObjectRow}
      />
    </Page>
  );
};

export default ITSystems;
