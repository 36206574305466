import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { getLocalStorageAppLanguage } from 'assets/js/serviceUtils';
import { LocalizationActions, LocalizationContext } from 'contexts/Localization/LocalizationContext';
import featuresService from 'services/featuresService';

import useError from './useError';
import useFeatureFlags from './useFeatureFlags';

const useLocalization = () => {
  const context = useContext(LocalizationContext);
  const { i18n } = useTranslation();
  const { handleServiceError } = useError();
  const { isPoeditorEnabled } = useFeatureFlags();

  if (context === undefined) {
    throw new Error('useLocalization must be used within a LocalizationProvider');
  }

  const { state, dispatch } = context;
  const { areTranslationsLoading, availableLanguages, translations } = state;

  const fetchAvailableLanguages = async () => {
    if (availableLanguages) return;

    dispatch({ type: LocalizationActions.GET_AVAILABLE_LANGUAGES });
    try {
      const { data } = await featuresService.getAvailableLanguages();
      const languages = Object.keys(data).filter((key) => key !== 'default');
      const payload = {
        availableLanguages: languages,
        defaultLanguage: data.default,
        optionalLanguages: languages.filter((key) => !data[key]),
      };
      const localStorageAppLanguage = getLocalStorageAppLanguage();
      if (!localStorageAppLanguage || !languages.includes(localStorageAppLanguage)) i18n.changeLanguage(data.default);
      dispatch({ type: LocalizationActions.GET_AVAILABLE_LANGUAGES_SUCCESS, payload });
    } catch (error) {
      dispatch({ type: LocalizationActions.GET_AVAILABLE_LANGUAGES_ERROR });
      handleServiceError(error, { shouldRefreshPage: true });
    }
  };

  const fetchTranslations = async () => {
    if (translations || areTranslationsLoading || !isPoeditorEnabled) return;

    dispatch({ type: LocalizationActions.GET_TRANSLATIONS });
    try {
      const { data } = await featuresService.getTranslations();
      Object.keys(data).forEach((lang) => {
        i18n.addResourceBundle(lang, 'common', data[lang]);
      });
      const payload = data;
      dispatch({ type: LocalizationActions.GET_TRANSLATIONS_SUCCESS, payload });
    } catch (error) {
      dispatch({ type: LocalizationActions.GET_TRANSLATIONS_ERROR });
      setTimeout(() => {
        handleServiceError(error, { shouldRefreshPage: true });
      }, 60000);
    }
  };

  return {
    ...state,
    fetchAvailableLanguages,
    fetchTranslations,
  };
};

export default useLocalization;
