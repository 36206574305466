import { useEffect, useRef } from 'react';

import { useAuth as useOidcAuth } from 'react-oidc-context';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ADMINISTRATOR, MODELER, QI, QI_MODELER } from 'assets/constants/constants';
import * as serviceUtils from 'assets/js/serviceUtils';
import DiagramRedirection from 'components/DiagramRedirection/DiagramRedirection';
import TelemetryProvider from 'components/Telemetry/TelemetryProvider';
import TitleBar from 'components/TitleBar/TitleBar';
import { ToastContextProvider } from 'components/UI/ToastContext/ToastContext';
import { DiagramProvider } from 'contexts/Diagram/DiagramContext';
import { DiagramConfigProvider } from 'contexts/DiagramConfig/DiagramConfigContext';
import { FormTypesProvider } from 'contexts/FormTypes/FormTypesContext';
import { TypesProvider } from 'contexts/Types/TypesContext';
import useAuth from 'hooks/useAuth';
import useFeatureFlags from 'hooks/useFeatureFlags';
import Administration from 'pages/Administration/Administration';
import DashboardWrapper from 'pages/Dashboard/DashboardWrapper';
import DatasetListWrapper from 'pages/DatasetList/DatasetListWrapper';
import DepartmentView from 'pages/DepartmentView/DepartmentView';
import DiagramCustom from 'pages/DiagramCustom/DiagramCustom';
import InputOutput from 'pages/InputOutput/InputOutputWrapper';
import ITSystems from 'pages/ITSystems/ITSystems';
import Landscape from 'pages/Landscape/Landscape';
import Maintenance from 'pages/Maintenance/Maintenance';
import MyTasksWrapper from 'pages/MyTasks/MyTasksWrapper';
import Processes from 'pages/Processes/Processes';
import ProcessLevelDatabaseWrapper from 'pages/ProcessLevelDatabase/ProcessLevelDatabaseWrapper';
import ProcessRecommendations from 'pages/ProcessRecommendations/ProcessRecommendations';
import Recommendation from 'pages/Recommendation/Recommendation';
import RecommendationsList from 'pages/RecommendationsList/RecommendationsList';
import RecycleBin from 'pages/RecycleBin/RecycleBin';
import RequirementListWrapper from 'pages/Requirements/RequirementListWrapper';
import Roles from 'pages/Roles/Roles';
import Sandbox from 'pages/Sandbox/Sandbox';
import Search from 'pages/Search/Search';
import UserConfig from 'pages/UserConfig/UserConfig';
import Workflows from 'pages/Workflows/Workflows';
import { getAppInsights } from 'services/telemetryService';

import './App.scss';

const App = () => {
  const appInsights = useRef(null);
  const { areRequirementsEnabled, isLandscapeEnabled, showRecommendationOverview, isLoaded, isFreezed } = useFeatureFlags();
  const { checkPermission, checkRoles, cleanupAuth, initAuth, isMaintenance, isLogged } = useAuth(true);
  const auth = useOidcAuth();

  useEffect(() => {
    if (!auth.isAuthenticated) {
      initAuth();
    }

    return () => {
      cleanupAuth();
    };
  }, [auth.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TelemetryProvider
      after={() => {
        appInsights.current = getAppInsights();
      }}
    >
      <ToastContextProvider>
        <TypesProvider>
          <FormTypesProvider>
            <DiagramProvider>
              <DiagramConfigProvider>
                {((process.env.REACT_APP_ENV === 'local' && !isLogged) ||
                  (process.env.REACT_APP_ENV !== 'local' && !auth.isAuthenticated)) && (
                  <>
                    <TitleBar isLoading />
                    <span className="check-user">Checking user...</span>
                  </>
                )}
                {auth.isAuthenticated && isMaintenance && (
                  <Switch>
                    <Route component={Maintenance} path="/maintenance" />
                    <Route component={UserConfig} path="/user" />
                    <Redirect to="/maintenance" />
                  </Switch>
                )}
                {(auth.isAuthenticated || isLogged) && !isMaintenance && isLoaded && (
                  <Switch>
                    {isLandscapeEnabled && <Route component={Landscape} exact path="/diagram/:e2e?/1" />}
                    {isLandscapeEnabled && <Route component={Landscape} exact path="/published/:e2e?/1" />}
                    <Route component={DiagramRedirection} path="/diagram/:e2e?/:id" />
                    <Route component={DiagramRedirection} path="/published/:e2e?/:id" />
                    <Route component={DiagramRedirection} path="/closed-workflow/:id" />
                    <Route component={DiagramRedirection} path="/sandbox/:id" />
                    <Route component={Recommendation} path="/recommendation/:id" />
                    <Route component={Sandbox} path="/sandbox-overview" />
                    <Route component={DiagramCustom} path="/custom-diagram" />
                    <Route component={DashboardWrapper} path="/dashboard" />
                    <Route component={MyTasksWrapper} path="/my-tasks" />
                    <Route component={Processes} path="/processes" />
                    <Route component={UserConfig} path="/user" />
                    <Route component={DepartmentView} path="/department-view" />
                    <Route component={Search} path="/search" />
                    {checkPermission('vcdWrite') && <Route component={ITSystems} path="/it-systems" />}
                    {checkPermission('vcdWrite') && <Route component={Roles} path="/roles" />}
                    {checkPermission('vcdWrite') && <Route component={Workflows} path="/workflows" />}
                    {checkPermission('vcdWrite') && (
                      <Route path="/recycle-bin" render={(props) => <RecycleBin {...props} isFreezed={isFreezed} />} />
                    )}
                    {checkPermission('vcdWrite') && <Route component={InputOutput} path="/input-output" />}
                    {checkPermission('vcdWrite') && <Route component={InputOutput} path="/input-output-swimlane" />}
                    {checkPermission('vcdWrite') && <Route component={ITSystems} path="/it-systems-swimlane" />}
                    {checkPermission('vcdWrite') && <Route component={Roles} path="/roles-swimlane" />}
                    {checkPermission('vcdWrite') && <Route component={Administration} path="/administration" />}
                    <Route component={DiagramRedirection} path="/dataset/:id" />
                    <Route component={DiagramRedirection} path="/dataset-published/:id" />
                    {checkRoles([ADMINISTRATOR, MODELER, QI, QI_MODELER]) && (
                      <Route component={DatasetListWrapper} path="/datasets" />
                    )}
                    {areRequirementsEnabled && serviceUtils.isCentral() && (
                      <Route component={DiagramRedirection} path="/requirements/:id" />
                    )}
                    {areRequirementsEnabled && serviceUtils.isCentral() && (
                      <Route component={RequirementListWrapper} path="/requirements" />
                    )}
                    {serviceUtils.isCentral() && <Route component={ProcessLevelDatabaseWrapper} path="/process-level-database" />}
                    {showRecommendationOverview && <Route component={RecommendationsList} path="/recommendations" />}
                    {showRecommendationOverview && (
                      <Route component={ProcessRecommendations} path="/process-recommendations/:id" />
                    )}
                    <Redirect to="/dashboard" />
                  </Switch>
                )}
              </DiagramConfigProvider>
            </DiagramProvider>
          </FormTypesProvider>
        </TypesProvider>
      </ToastContextProvider>
    </TelemetryProvider>
  );
};

export default App;
