import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BUTTON_PRIMARY } from 'assets/constants/constants';
import * as serviceUtils from 'assets/js/serviceUtils';
import SandboxDraft from 'components/SandboxDraft/SandboxDraft';
import * as Utils from 'components/SandboxDraft/Utils';
import TitleBar from 'components/TitleBar/TitleBar';
import DialogWarning from 'components/UI/DialogBasics/DialogWarning';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import useAuth from 'hooks/useAuth';
import useLocalization from 'hooks/useLocalization';
import { ProcessType } from 'types/processes';

import styles from './Sandbox.module.scss';

import 'App.scss';

const Sandbox = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { userInfo, setVisitedSandbox } = useAuth();
  const { availableLanguages } = useLocalization();
  const [treeSandbox, setTreeSandbox] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sandboxSearch, setSandboxSearch] = useState([]);
  const [succesDialog, setSuccessDialog] = useState({ open: false, text: '', id: '' });
  const language = i18n.language.toUpperCase();

  const sandboxClick = (diagramId) => {
    history.push(`/sandbox/${diagramId}`);
  };

  const createSandbox = async (type = ProcessType.EPC) => {
    setLoading(true);
    const { sandboxTree, draftName, diagramId } = await Utils.createSandbox(treeSandbox, language, availableLanguages, type);
    setSuccessDialog({ open: true, text: draftName, id: diagramId });
    setTreeSandbox(sandboxTree);
    setLoading(false);
    setSandboxSearch(sandboxTree);
  };

  const loadSandbox = async () => {
    let sandboxTree = await Utils.loadSandboxs(t);

    if (!serviceUtils.isCentral()) {
      const sandboxRecommendedTree = await Utils.loadRecommendedSandboxs(t);
      sandboxTree = [
        ...sandboxTree,
        ...sandboxRecommendedTree.map((recommendation) => ({ ...recommendation, isRecommendation: true })),
      ];
    }
    setTreeSandbox(sandboxTree);
    setLoading(false);
    setSandboxSearch(sandboxTree);
  };

  useEffect(() => {
    loadSandbox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitleBar hiddenWarning />
      <div className={styles.Content}>
        <div className={styles.Draft}>
          <SandboxDraft
            addSandbox={createSandbox}
            handleClick={sandboxClick}
            handleSearch={(text) => setSandboxSearch(Utils.searchSandbox(text, treeSandbox, language))}
            isDefault
            loadingSandbox={loading}
            results={sandboxSearch}
            sandboxTree={treeSandbox}
          />
        </div>
        {userInfo && !userInfo?.metadata?.VISITED_SANDBOX && (
          <DialogWarning confirmText={t('ok')} handleConfirm={setVisitedSandbox} hasIcon>
            {t('sandboxFirstTimeDialog')}
          </DialogWarning>
        )}
        {succesDialog.open && (
          <DialogNEPOS
            dialog={{
              buttons: [
                {
                  buttonStyle: BUTTON_PRIMARY,
                  content: t('ok'),
                  handleClick: () => {
                    sandboxClick(succesDialog.id);
                    setSuccessDialog({ state: false, text: '', id: '' });
                  },
                  id: 'ModalButton-OK',
                  key: 'submit',
                },
              ],
              title: t('success'),
            }}
            extraClass="Modal"
          >
            <p>{t('sandboxDraftCreated', { draftName: succesDialog.text })}</p>
          </DialogNEPOS>
        )}
      </div>
    </>
  );
};

export default Sandbox;
