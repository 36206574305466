import styles from './WarningMessage.module.scss';

const WarningMessage = () => (
  <div className={styles.WarningMessage}>
    <p>
      Attention: MBC MAP will be migrated to Signavio. All data will be freezed on July 1st. No Sandbox processes and no pictures
      (jpg, png, etc.) will be migrated!
      <br />
      Bitte beachten: MBC MAP wird auf Signavio migriert. Ab 1. Juli können die Prozesse nicht mehr bearbeitet werden.
      Sandboxprozesse und Bilder werden nicht migriert!
    </p>
  </div>
);

export default WarningMessage;
