import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ZOOM_OUT, ZOOM_IN, ZOOM_TO_FIT, ZOOM_FACTOR, ENVIRONMENTS } from 'assets/constants/constants';
import { MANAGEMENT_PROCESSES, ENABLER_PROCESSES } from 'assets/constants/landscapeProcesses';
import triangleBottom from 'assets/images/landscapeBottomTriangle.svg';
import triangleTop from 'assets/images/landscapeTopTriangle.svg';
import { prepareTree } from 'assets/js/Utils';
import CoreProcesses from 'components/CoreProcesses/CoreProcesses';
import DiagramExplorer from 'components/DiagramExplorer/DiagramExplorer';
import LandscapeProcess from 'components/LandscapeProcess/LandscapeProcess';
import TitleBar from 'components/TitleBar/TitleBar';
import Toolbar from 'components/Toolbar/Toolbar';
import Spinner from 'components/UI/Spinner/Spinner';
import useAuth from 'hooks/useAuth';
import diagramServices from 'services/diagramService';

import styles from './Landscape.module.scss';

const Landscape = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { env } = useAuth();
  const diagramRef = useRef();
  const scrollableRef = useRef();
  const [language, setLanguage] = useState(i18n.language.toUpperCase());
  const [tree, setTree] = useState([]);
  const [isTreeLoading, setIsTreeLoading] = useState(true);
  const [isExplorerOpen, setIsExplorerOpen] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [diagramCenter, setDiagramCenter] = useState();
  const isPublished = location.pathname.includes('published');
  const params = useParams();
  const { e2e } = params;

  const zoomDiagram = (zoomType) => {
    let newZoom = zoom;

    if (zoomType === ZOOM_OUT) {
      // Reset zoom to 1 when subtraction is not well rounded (0.99 periodic). Zoom can't be less than 1.
      newZoom = Math.max(1, newZoom - ZOOM_FACTOR);
    } else if (zoomType === ZOOM_IN) {
      newZoom += ZOOM_FACTOR;
    } else {
      newZoom = 1;
    }

    scrollableRef.current.style.transform = `scale(${newZoom})`;
    const hasHorizontalOverflow =
      scrollableRef.current.getBoundingClientRect().width > diagramRef.current.getBoundingClientRect().width;
    scrollableRef.current.style.transformOrigin = hasHorizontalOverflow ? 'top left' : 'top center';
    diagramRef.current.style.alignItems = hasHorizontalOverflow ? 'flex-start' : 'center';
    diagramRef.current.style.justifyContent = hasHorizontalOverflow ? 'flex-start' : 'center';
    setZoom(newZoom);
  };

  const zoomIcons = [
    {
      id: ZOOM_OUT,
      tooltip: t('tool.zoomOut'),
      iconClass: 'di icon-lupe-herauszoomen',
      disabled: zoom === 1,
      click: () => zoomDiagram(ZOOM_OUT),
    },
    {
      id: ZOOM_IN,
      tooltip: t('tool.zoomIn'),
      iconClass: 'di icon-lupe-hineinzoomen',
      disabled: false,
      click: () => zoomDiagram(ZOOM_IN),
    },
    {
      id: ZOOM_TO_FIT,
      tooltip: t('tool.zoomToFit'),
      iconClass: 'di icon-zoom-anpassen-fokus',
      disabled: false,
      click: () => zoomDiagram(ZOOM_TO_FIT),
    },
  ];

  const getTree = useCallback(() => {
    diagramServices
      .getDiagramTreeStructure(isPublished)
      .then((response) => {
        setTree(prepareTree(response.data.results, language));
        setIsTreeLoading(false);
      })
      .catch((err) => {
        setIsTreeLoading(false);
        diagramServices.handleServiceError(err);
      });
  }, [isPublished, language]);

  useEffect(() => {
    setIsTreeLoading(true);

    getTree();
  }, [getTree, location.pathname]);

  const landscapeType = (process) => {
    if (e2e) {
      return process.id.E2E;
    }
    return process.id[env] || process.id[ENVIRONMENTS.PRO];
  };

  useEffect(() => {
    const handleResize = () => {
      setDiagramCenter({
        x: scrollableRef.current?.getBoundingClientRect().left + scrollableRef.current?.getBoundingClientRect().width / 2,
        y: scrollableRef.current?.getBoundingClientRect().top + scrollableRef.current?.getBoundingClientRect().height / 2,
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleTreeNodeClick = ({ nodeData: { id } }) => {
    const e2eLandscape = e2e ? `/${e2e}` : '';
    const route = `/${isPublished ? 'published' : 'diagram'}${e2eLandscape}/${id}`;

    history.push(route);
  };

  return (
    <>
      <TitleBar isLoading={false} />
      <Toolbar getLanguage={setLanguage} isDiagram language={language} onlyRead zoomIcons={zoomIcons} />
      <div className={styles.DiagramWrapper}>
        {!diagramCenter?.x && (
          <div className={styles.LoadingLayer}>
            <Spinner isVisible />
          </div>
        )}
        <div className={`${styles.DiagramExplorer} ${isExplorerOpen}`}>
          <div className={styles.ExplorerWrapper}>
            <DiagramExplorer handleClick={handleTreeNodeClick} lang={language} loadingTree={isTreeLoading} tree={tree} />
          </div>
          <div
            className={styles.ToggleButton}
            id="explorerExpanded"
            onClick={() => setIsExplorerOpen(isExplorerOpen ? '' : styles.OpenExplorer)}
          >
            <i className="fas fa-angle-double-right" />
          </div>
        </div>
        <div className={styles.Diagram} ref={diagramRef}>
          <div className={styles.Scrollable} id="diagram-content" ref={scrollableRef}>
            <div className={styles.Content}>
              <div className={styles.ManagementProcesses}>
                <div className={styles.SidePanel}>
                  <div>{t('landscape.managementProcesses', { lng: language })}</div>
                </div>
                <div className={styles.Processes}>
                  {MANAGEMENT_PROCESSES.map((process) => (
                    <LandscapeProcess
                      diagramCenter={diagramCenter}
                      e2e={e2e}
                      id={landscapeType(process)}
                      images={process.imgs}
                      isManagementProcess
                      key={landscapeType(process)}
                      processName={process.processName[language]}
                      url={process.url}
                    />
                  ))}
                </div>
              </div>
              <div className={styles.CoreProcesses}>
                <img alt="Top Triangle" className={styles.TriangleImages} id="toptriangle" src={triangleTop} />
                <CoreProcesses e2e={e2e} language={language} />
                <img alt="Bottom Triangle" className={styles.TriangleImages} id="bottomtriangle" src={triangleBottom} />
              </div>
              <div className={styles.EnablerProcesses}>
                <div className={styles.Processes}>
                  {ENABLER_PROCESSES.map((process) => (
                    <LandscapeProcess
                      diagramCenter={diagramCenter}
                      e2e={e2e}
                      id={landscapeType(process)}
                      images={process.imgs}
                      key={landscapeType(process)}
                      processName={process.processName[language]}
                      url={process.url}
                    />
                  ))}
                </div>
                <div className={styles.SidePanel}>
                  <div>{t('landscape.enablerProcesses', { lng: language })}</div>
                </div>
              </div>
            </div>
            <div className={styles.BorderTop}>
              <div className={`${styles.BorderTextGeneral} ${styles.BorderTopText}`}>
                {t('landscape.customerSatisfaction', { lng: language })}
              </div>
              {e2e && (
                <div className={styles.Tag}>
                  <img
                    alt="E2E tag"
                    src="https://mbcblobstorageprod.blob.core.windows.net/frontend-static-assets/diagram-tag/NEPOS.JPG"
                  />
                </div>
              )}
            </div>
            <div className={styles.BorderMiddle}>
              <i className={`di icon-form-dreieck ${styles.LeftArrow}`} />
              <i className={`di icon-form-dreieck ${styles.RightArrow}`} />
            </div>
            <div className={styles.BorderBottom}>
              <i className={`di icon-form-dreieck ${styles.LeftArrow}`} />
              <i className={`di icon-form-dreieck ${styles.RightArrow}`} />
              <div className={`${styles.BorderTextGeneral} ${styles.BorderBottomText}`}>
                {t('landscape.customerRequirements', { lng: language })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landscape;
