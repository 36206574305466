import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Search from 'components/UI/Search/Search';
import Spinner from 'components/UI/Spinner/Spinner';
import useAuth from 'hooks/useAuth';
import useError from 'hooks/useError';
import userService from 'services/userService';

import styles from './ScopesExplorer.module.scss';

const ScopesExplorer = ({ departmentId, setDepartment }) => {
  const { i18n, t } = useTranslation();
  const { handleServiceError } = useError();
  const { userInfo } = useAuth();
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [children, setChildren] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [departmentDisplayName, setDepartmentDisplayName] = useState();
  const language = i18n.language.toUpperCase();

  const handleSearch = async (searchString) => {
    if (!searchString) return new Promise((resolve) => resolve(null));
    try {
      const response = await userService.getScopesByName({
        page: 0,
        pageSize: 50,
        sortDir: 'ASC',
        searchString,
      });
      const scopeList = response.data.results.map((scope) => ({
        value: scope.departmentId,
        label: `${scope.abbreviation} - ${JSON.parse(scope.description)[language]}`,
      }));

      return scopeList;
    } catch (error) {
      handleServiceError(error);
    }
  };

  useEffect(() => {
    if (!userInfo) return;
    setDepartment({ departmentId: userInfo?.departmentId });
  }, [userInfo?.departmentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!departmentId) {
      return;
    }

    setIsLoading(true);
    userService
      .getScopesList(departmentId)
      .then((res) => {
        setIsLoading(false);
        const scopeName = res.data.scope?.description
          ? `${res.data.scope.abbreviation} - ${JSON.parse(res.data.scope.description)[language]}`
          : '';
        setDepartment({ ...res.data.scope, displayName: scopeName });
        if (!departmentDisplayName) setDepartmentDisplayName(scopeName);
        setBreadcrumb([...res.data.breadcrumb, res.data.scope]);
        setChildren(res.data.children);
      })
      .catch((err) => {
        setIsLoading(false);
        userService.handleServiceError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const onClickUserInfo = () => {
    if (userInfo.departmentId === departmentId) return;
    setDepartment({ departmentId: userInfo.departmentId });
  };

  const handleClickDropdownItem = (id) => {
    if (departmentId === id) return;
    setDepartment({ departmentId: id });
  };

  return (
    <div className={styles.ScopesExplorer}>
      {isLoading ? (
        <div className="spinner">
          <Spinner isVisible />
        </div>
      ) : (
        <>
          <span className={styles.SearchHint}>{t('departmentView.searchDepartment')}</span>
          <Search
            hasDropdown
            isAsync
            language={language}
            onClickDropdownItem={(id) => handleClickDropdownItem(id)}
            searching={handleSearch}
          />
          <div
            className={`${styles.UserInfo} ${userInfo.departmentId !== departmentId ? styles.NotSelected : ''}`}
            key={userInfo.departmentId}
            onClick={onClickUserInfo}
          >
            <span>{`${userInfo.givenName} ${userInfo.surname}`}</span>
            <span className={`${styles.DepartmentName} ${userInfo.departmentId !== departmentId ? styles.NotSelected : ''}`}>
              {departmentDisplayName}
            </span>
          </div>
          <div className={styles.Line} />
          <div className={styles.BreadCrumb}>
            {breadcrumb.map((scope, i) => {
              if (breadcrumb.length > 5 && i > 1 && i < breadcrumb.length - 3) return null;

              if (breadcrumb.length > 4 && i === 1) {
                return (
                  <div className={styles.BreadCrumbItem} key={scope.departmentId}>
                    <span>...</span>
                    <i className="di icon-pfeil-chevron-rechts" />
                  </div>
                );
              }

              const onClick = i === breadcrumb.length - 1 ? () => {} : () => setDepartment(scope);

              return (
                <div className={styles.BreadCrumbItem} key={scope.departmentId} onClick={onClick}>
                  {`${scope.abbreviation} - ${JSON.parse(scope.description)[language]}`}
                  {breadcrumb.length - 1 === i ? '' : <i className="di icon-pfeil-chevron-rechts" />}
                </div>
              );
            })}
          </div>
          <div className={styles.ScopesList}>
            {children.map((scope) => (
              <div className={styles.Scope} key={scope.departmentId} onClick={() => setDepartment(scope)}>
                <i className="di icon-pfeil-chevron-rechts" />
                <span>{`${scope.abbreviation} - ${JSON.parse(scope.description)[language]}`}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ScopesExplorer;
