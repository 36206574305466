import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ROLE, ROLE_SWIMLANE, SWIMLANE } from 'assets/constants/constants';
import CatalogObjectTable from 'components/CatalogObjectTable/CatalogObjectTable';
import Page from 'components/Page/Page';
import TitleBar from 'components/TitleBar/TitleBar';
import CatalogObjectRow from 'components/UI/TableList/CatalogObjectRow/CatalogObjectRow';
import useEnvironment from 'hooks/useEnvironment';
import usePagination from 'hooks/usePagination';
import { findAllByObjectType } from 'services/design';
import titleService from 'services/titleService';

const Roles = () => {
  const { t } = useTranslation();
  const checkEnvironment = useEnvironment();
  const [catalogUsers, setCatalogUsers] = useState({});

  const isSwimlane = checkEnvironment(SWIMLANE);
  const labelX = isSwimlane ? `${t('roles')} (${t('NEPOS_SWIMLANE')})` : t('roles');
  const roleType = isSwimlane ? ROLE_SWIMLANE : ROLE;

  titleService.updatePageTitle(labelX);

  const getResults = useCallback(
    (params) =>
      findAllByObjectType(roleType, isSwimlane ? params : { ...params, withUser: true }).then((response) => {
        setCatalogUsers(response.data?.catalog);
        return response.data;
      }),
    [roleType, isSwimlane],
  );

  const pagination = usePagination({ getResults });

  const tableHeader = useMemo(
    () => ({
      area: t('nameAttributes.tables.AREA'),
      created: t('nameAttributes.tables.CREATED'),
      description: t('nameAttributes.OBJECT_DESCRIPTION'),
      name: t('nameAttributes.OBJECT_NAME'),
      usedIn: t('nameAttributes.tables.USED_IN'),
      ...(!isSwimlane && {
        authorProcess: t('nameAttributes.AUTHOR_PROCESS'),
        requester: t('nameAttributes.objectCatalog.requestedBy', { objectType: t(ROLE) }),
        approver: t('nameAttributes.objectCatalog.releasedBy', { objectType: t(ROLE) }),
      }),
    }),
    [t, isSwimlane],
  );

  return (
    <>
      <Page>
        <CatalogObjectTable
          catalogUsers={catalogUsers}
          header={tableHeader}
          labelX={labelX}
          objectType={roleType}
          pagination={pagination}
          rowComponent={CatalogObjectRow}
        />
      </Page>
    </>
  );
};

export default Roles;
