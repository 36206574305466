import { DatasetProvider } from 'contexts/Dataset/DatasetContext';
import { DiagramProvider } from 'contexts/Diagram/DiagramContext';
import { ProcessLevelProvider } from 'contexts/ProcessLevel/ProcessLevelContext';

import ProcessLevelDatabase from './ProcessLevelDatabase';

const ProcessLevelDatabaseWrapper = () => {
  return (
    <DiagramProvider>
      <DatasetProvider>
        <ProcessLevelProvider>
          <ProcessLevelDatabase />
        </ProcessLevelProvider>
      </DatasetProvider>
    </DiagramProvider>
  );
};

export default ProcessLevelDatabaseWrapper;
