import { useCallback, useEffect, useRef, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { BUTTON_PRIMARY, BUTTON_SECONDARY, REQUIREMENT_INITIAL_PAGE, REQUIREMENT_PAGE_SIZE } from 'assets/constants/constants';
import { handleFilters } from 'assets/js/Utils';
import Page from 'components/Page/Page';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import CheckboxNEPOS from 'components/UI/CheckboxNEPOS/CheckboxNEPOS';
import DeleteDialog from 'components/UI/DeleteDialog/DeleteDialog';
import DialogFooter from 'components/UI/DialogFooter/DialogFooter';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import DropdownPanel from 'components/UI/DropdownPanel/DropdownPanel';
import IconButton from 'components/UI/IconButton/IconButton';
import IconButtonDropdown from 'components/UI/IconButtonDropdown/IconButtonDropdown';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import SearchNEPOS from 'components/UI/SearchNEPOS/SearchNEPOS';
import Table from 'components/UI/Table/Table';
import TextButton from 'components/UI/TextButton/TextButton';
import useAuth from 'hooks/useAuth';
import useError from 'hooks/useError';
import useFeatureFlags from 'hooks/useFeatureFlags';
import usePagination from 'hooks/usePagination';
import useProcessLevelDatabase from 'hooks/useProcessLevel';
import useRequirement from 'hooks/useRequirement';
import useRequirementContext from 'hooks/useRequirementContext';
import ProcessLevelDatabaseTable from 'pages/ProcessLevelDatabase/ProcessLevelDatabaseTable';
import { setSupportNeeded, saveColumnsConfig, copyRequirement } from 'services/requirement';
import titleService from 'services/titleService';
import { Coordinate } from 'types/diagram';
import { DeleteDialogPrefixes, DialogType } from 'types/dialogs';
import { ProcessLevelFormatted } from 'types/processLevel';
import {
  ColumnsConfig,
  Requirement,
  RequirementActions,
  RequirementCategories,
  RequirementFilters,
  RequirementStatus,
} from 'types/requirement';
import { Pagination, TableColumn, TableVariant } from 'types/tables';

import styles from './RequirementList.module.scss';

const RequirementList = () => {
  const { t } = useTranslation();
  const { fetchAllProcesses } = useProcessLevelDatabase();
  const { createNewRequirement, fetchAllRequirements, deleteExistingRequirement, triggerRequirementActionFromList } =
    useRequirement();
  const { savedColumnsConfig } = useRequirementContext();
  const { userInfo } = useAuth();
  const history = useHistory();
  const { handleServiceError } = useError();
  const buttonRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
  const [isCopyButtonDisabled, setIsCopyButtonDisabled] = useState(true);
  const [isRetrieveButtonDisabled, setIsRetrieveButtonDisabled] = useState(true);
  const [isSupportNeededButtonDisabled, setISSupportNeededButtonDisabled] = useState(true);
  const [showDeleteRequirementDialog, setShowDeleteRequirementDialog] = useState(false);
  const [showConfirmRetrieveDialog, setShowConfirmRetrieveDialog] = useState<boolean>(false);
  const [showRetrieveDialog, setShowRetrieveDialog] = useState<boolean>(false);
  const [isColumnsSelectionPanelOpen, setIsColumnsSelectionPanelOpen] = useState(false);
  const [isSupportNeededDialog, setIsSupportNeededDialog] = useState<boolean>(false);
  const [columnsSelected, setColumnsSelected] = useState<string[]>([]);
  const [columnsConfig, setColumnsConfig] = useState<ColumnsConfig>();
  const [columnsToShow, setColumnsToShow] = useState<TableColumn<Requirement>[]>();
  const [selectedItem, setSelectedItem] = useState<Requirement>();
  const [categoryFilters, setCategoryFilters] = useState<string[]>([]);
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [supportNeededFilters, setSupportNeededFilters] = useState<string[]>([]);
  const [processIdFilter, setProcessIdFilter] = useState<string[]>([]);
  const [showProcessFilterDialog, setShowProcessFilterDialog] = useState(false);
  const [processLevelResults, setProcessLevelResults] = useState<ProcessLevelFormatted[]>();
  const [selectedProcessId, setSelectedProcessId] = useState<string>('');
  const [selectedProcessTitle, setSelectedProcessTitle] = useState<string | undefined>('');
  const [searchText, setSearchText] = useState<string>();
  const {
    isLoading,
    results,
    totalPages,
    page,
    setPage,
    fetchResults,
    handleSearchChange,
    sortTable,
    sorting,
    handleFiltersChange,
  } = usePagination({
    getResults: fetchAllRequirements,
    pageSize: REQUIREMENT_PAGE_SIZE,
    initialPage: REQUIREMENT_INITIAL_PAGE,
    auxFetchParam: columnsConfig as any,
  });
  titleService.updatePageTitle('Requirements');
  const { isFreezed } = useFeatureFlags();

  const requirementsColumns: TableColumn<Requirement>[] = [
    { id: 'supportNeeded', style: styles.SupportNeeded },
    { id: 'id', style: styles.Id, isSortable: true },
    { id: 'title', style: styles.Title, isSortable: true },
    { id: 'category', style: styles.Category, isSortable: true },
    { id: 'status', style: styles.Status, isSortable: true },
    { id: 'description', style: styles.Description, isSortable: true },
    { id: 'example', style: styles.Example, isSortable: true },
    { id: 'responsiblePerson', style: styles.ResponsiblePerson, isSortable: true },
    { id: 'responsiblePacemaker', style: styles.ResponsiblePacemaker, isSortable: true },
    { id: 'responsibleProcess', style: styles.ResponsibleProcess, isSortable: true },
    { id: 'originProcess', style: styles.OriginProcess, isSortable: true },
    { id: 'createdBy', style: styles.CreatedBy, isSortable: true },
    { id: 'creationInfo', style: styles.CreationInfo, isSortable: true },
    { id: 'acceptedBy', style: styles.AcceptedBy, isSortable: true },
    { id: 'acceptanceInfo', style: styles.AcceptanceInfo, isSortable: true },
    { id: 'potentialAnnualEbit', style: styles.PotentialAnnualEbit, isSortable: true },
    { id: 'potentialOneTimeCashflow', style: styles.PotentialOneTimeCashflow, isSortable: true },
    { id: 'solutionDescription', style: styles.SolutionDescription, isSortable: true },
    { id: 'requiredInvest', style: styles.RequiredInvest, isSortable: true },
    { id: 'evaluationAnnualEbit', style: styles.EvaluationAnnualEbit, isSortable: true },
    { id: 'evaluationOneTimeCashflow', style: styles.EvaluationOneTimeCashflow, isSortable: true },
    { id: 'targetDate', style: styles.TargetDate, isSortable: true },
  ];

  const processLevelColumns: TableColumn<ProcessLevelFormatted>[] = [
    { id: 'title', style: styles.ProcessTitle },
    { id: 'level', style: styles.ProcessLevel },
    { id: 'processResponsibles', style: styles.ProcessResponsibles },
    { id: 'department', style: styles.Department },
  ];

  useEffect(() => {
    setColumnsConfig(savedColumnsConfig);
  }, [savedColumnsConfig]);

  useEffect(() => {
    if (columnsConfig)
      setColumnsSelected(
        Object.keys(columnsConfig as ColumnsConfig).filter((column) => columnsConfig[column as keyof ColumnsConfig] === true),
      );
  }, [columnsConfig]);

  useEffect(() => {
    let newColumnsToShow: TableColumn<Requirement>[] = [];

    if (columnsConfig) {
      newColumnsToShow = requirementsColumns.filter((column) => columnsConfig[column.id as keyof ColumnsConfig] === true);
      newColumnsToShow.unshift({ id: 'supportNeeded', style: styles.SupportNeeded });
    }

    setColumnsToShow(newColumnsToShow);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsConfig]);

  useEffect(() => {
    if (!results) return;
    setSelectedItem(
      (results as Requirement[]).find((requirement: Requirement) => requirement.id.toString() === selectedItems[0]),
    );
  }, [results, selectedItems]);

  useEffect(() => {
    if (processLevelResults) return;
    fetchAllProcesses(true)
      .then((res) => setProcessLevelResults(res?.finalResults))
      .catch((err) => handleServiceError(err));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleActionButtons = () => {
    if (!results) return;
    setISSupportNeededButtonDisabled(!(selectedItems.length > 0));
    setIsRetrieveButtonDisabled(
      !(
        selectedItems.length === 1 &&
        selectedItem?.creator === userInfo?.code &&
        (selectedItem?.status === RequirementStatus.PENDING || selectedItem?.status === RequirementStatus.RETRIEVED)
      ),
    );
    setIsDeleteButtonDisabled(!(selectedItems.length === 1 && selectedItem?.creator === userInfo?.code));
    setIsCopyButtonDisabled(selectedItems.length !== 1 || !selectedItem?.id);
  };

  useEffect(() => {
    handleActionButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, selectedItem]);

  const handleConfirmDelete = useCallback(
    async (confirmationValue?: string) => {
      setShowDeleteRequirementDialog(false);
      if (!results || !confirmationValue) return;
      setSelectedItem(
        (results as Requirement[]).find((requirement: Requirement) => requirement.id.toString() === selectedItems[0]),
      );
      try {
        await deleteExistingRequirement(+selectedItems[0], confirmationValue);
        fetchResults();
      } catch (err) {
        handleServiceError(err);
      }
    },
    [results, selectedItems, deleteExistingRequirement, fetchResults, handleServiceError],
  );

  const handleSupportNeeded = useCallback(async () => {
    try {
      await setSupportNeeded(selectedItems);
      fetchResults();
      setIsSupportNeededDialog(false);
    } catch (err) {
      handleServiceError(err);
    }
  }, [selectedItems, fetchResults]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRetrieve = useCallback(async () => {
    setShowConfirmRetrieveDialog(false);
    if (!selectedItem) return;
    triggerRequirementActionFromList(Number(selectedItem.id), RequirementActions.RETRIEVE)
      .then(() => {
        setShowRetrieveDialog(true);
        fetchResults();
      })
      .catch((err) => handleServiceError(err));
  }, [selectedItems, fetchResults]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDropdownPanelCheck = (item: string) => {
    let newColumnsSelected = [...columnsSelected];

    if (columnsSelected.some((elem) => item === elem)) {
      newColumnsSelected = columnsSelected.filter((el) => el !== item);
    } else {
      newColumnsSelected = [...columnsSelected, item];
    }

    setColumnsSelected(newColumnsSelected);
  };

  const applyColumnsConfig = () => {
    if (!columnsConfig) return;

    let newColumnsConfig: ColumnsConfig = columnsConfig;
    requirementsColumns
      .filter((column) => column.id !== 'supportNeeded')
      .map((column) => {
        newColumnsConfig = { ...newColumnsConfig, [column.id]: columnsSelected.includes(column.id) };

        return newColumnsConfig;
      });

    setColumnsConfig(newColumnsConfig);

    const newColumnsToShow = requirementsColumns.filter((column) => columnsSelected.includes(column.id));
    newColumnsToShow.unshift({ id: 'supportNeeded', style: styles.SupportNeeded });

    setColumnsToShow(newColumnsToShow);
  };

  const resetColumnsConfig = () => {
    if (!columnsConfig) return;

    const allColumnsIds = requirementsColumns.filter((column) => column.id !== 'supportNeeded').map((column) => column.id);

    const newColumnsConfig: ColumnsConfig = columnsConfig;
    Object.keys(newColumnsConfig).map((key) => {
      newColumnsConfig[key as keyof ColumnsConfig] = true;

      return newColumnsConfig;
    });

    setColumnsSelected(allColumnsIds);
    setColumnsToShow(requirementsColumns);
    setColumnsConfig(newColumnsConfig);
  };

  const handleCopyRequirement = () => {
    if (!selectedItem?.id) return;
    copyRequirement(selectedItem.id)
      .then((res) => history.push(`/requirements/${res.data.id}`))
      .catch((error) => handleServiceError(error));
  };

  const dialogButtonsRetrieveConfirmDialog = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('ok'),
      handleClick: handleRetrieve,
      key: 'confirm-retrieve-button-submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      handleClick: () => setShowConfirmRetrieveDialog(false),
      key: 'confirm-retrieve-button-cancel',
    },
  ];

  const dropdownPanelButtons = [
    {
      id: 'requirement-columns-apply',
      key: 'requirement-columns-apply',
      handleClick: () => {
        applyColumnsConfig();
        setIsColumnsSelectionPanelOpen(false);
      },
      content: t('apply'),
      buttonStyle: BUTTON_PRIMARY,
      disabled: !columnsSelected,
    },
    {
      id: 'requirement-columns-reset',
      key: 'requirement-columns-reset',
      handleClick: () => {
        resetColumnsConfig();
        setIsColumnsSelectionPanelOpen(false);
      },
      content: t('reset'),
      buttonStyle: BUTTON_SECONDARY,
      disabled: false,
    },
  ];

  const supportDialog = {
    title: t('requirement.support'),
    type: DialogType.Info,
    buttons: [
      {
        id: 'requirement-support-needed',
        key: 'requirement-support-needed',
        handleClick: handleSupportNeeded,
        content: t('requirements.supportNeeded'),
        buttonStyle: BUTTON_PRIMARY,
      },
      {
        id: 'requirement-cancel-needed',
        key: 'requirement-cancel-needed',
        handleClick: () => {
          setIsSupportNeededDialog(false);
        },
        content: t('cancel'),
        buttonStyle: BUTTON_SECONDARY,
      },
    ],
  };

  const processFilterDialog = {
    buttons: [
      {
        buttonStyle: BUTTON_PRIMARY,
        content: t('select'),
        handleClick: () => {
          setShowProcessFilterDialog(false);
          handleFilters(
            selectedProcessId,
            RequirementFilters.PROCESS_ID,
            setProcessIdFilter,
            processIdFilter,
            handleFiltersChange,
          );
          setSelectedProcessTitle(processLevelResults?.find((result) => result.id.toString() === selectedProcessId)?.title);
          setSearchText('');
        },
        key: 'process-filter-button-submit',
      },
      {
        buttonStyle: BUTTON_SECONDARY,
        content: t('cancel'),
        handleClick: () => {
          setShowProcessFilterDialog(false);
          setSearchText('');
          if (selectedProcessId !== processIdFilter[0]) setSelectedProcessId(processIdFilter[0]);
          if (processIdFilter.length === 0 || processIdFilter[0] === '') {
            setSelectedProcessId('');
            setSelectedProcessTitle('');
          }
        },
        key: 'process-filter-button-cancel',
      },
    ],
    title: t('requirement.dialog.process-filter.title'),
  };

  return (
    <Page>
      <div className={styles.Wrapper}>
        <div className={styles.Container}>
          <div className={styles.Header}>
            <SearchNEPOS id="requirement-list-search" isAsync searching={handleSearchChange} />
            <IconButton
              dataQA="process-filter"
              extraClass={`${styles.FilterButton} ${selectedProcessTitle !== '' ? styles.Edited : ''}`}
              icon="di icon-filter"
              isEdited={selectedProcessTitle !== ''}
              onClick={() => setShowProcessFilterDialog(true)}
              resetButtonClick={() => {
                setSelectedProcessTitle('');
                setSelectedProcessId('');
                setProcessIdFilter([]);
                handleFilters('', RequirementFilters.PROCESS_ID, setProcessIdFilter, processIdFilter, handleFiltersChange);
              }}
            >
              {selectedProcessTitle !== '' ? selectedProcessTitle : t('processTab')}
            </IconButton>
            <IconButtonDropdown
              buttonStyle={styles.FilterButton}
              icon="di icon-filter"
              id="category-filter"
              isCheckboxDropdown
              onChange={(value: string) =>
                handleFilters(value, RequirementFilters.CATEGORY, setCategoryFilters, categoryFilters, handleFiltersChange)
              }
              options={Object.keys(RequirementCategories).map((option: string) => {
                return { value: option, label: t(`attributes.INITIAL_CATEGORY.options?.${option}`) };
              })}
              title={t('category')}
            />
            <IconButtonDropdown
              buttonStyle={styles.FilterButton}
              icon="di icon-filter"
              id="status-filter"
              isCheckboxDropdown
              onChange={(value: string) =>
                handleFilters(value, RequirementFilters.STATUS, setStatusFilters, statusFilters, handleFiltersChange)
              }
              options={Object.keys(RequirementStatus)
                .filter((option) => option !== RequirementStatus.FORWARD)
                .map((option: string) => {
                  return { value: option, label: t(`table.status.${option}`) };
                })}
              title={t('STATUS')}
            />
            <IconButtonDropdown
              buttonStyle={styles.FilterButton}
              icon="di icon-filter"
              id="support-needed-filter"
              isCheckboxDropdown
              onChange={(value: string) =>
                handleFilters(
                  value,
                  RequirementFilters.SUPPORT_NEEDED,
                  setSupportNeededFilters,
                  supportNeededFilters,
                  handleFiltersChange,
                )
              }
              options={['true', 'false'].map((option: string) => ({ value: option, label: t(`table.supportNeeded.${option}`) }))}
              preventMultipleSelection
              title={t('requirements.supportNeeded')}
            />
            <ButtonNEPOS
              className={styles.CreateButton}
              disabled={isFreezed || isLoading}
              handleClick={createNewRequirement}
              icon="di icon-plus-hinzufuegen-klein"
              id="create-new-requirement"
            >
              {t('requirements.button.create')}
            </ButtonNEPOS>
          </div>
          <div className={styles.Body}>
            <div className={`${styles.ButtonsBar} ${selectedItems.length ? styles.Checked : ''}`}>
              {!!selectedItems.length && <span>{`${selectedItems.length} ${t('entrySelected')}`}</span>}
              <div className={styles.ButtonWrapper}>
                <TextButton
                  disabled={isFreezed || isRetrieveButtonDisabled}
                  id="requirements-retrieve"
                  onClick={() => {
                    setShowConfirmRetrieveDialog(true);
                  }}
                  text={t('requirements.overview.retrieve')}
                />
                <TextButton
                  disabled={isFreezed || isSupportNeededButtonDisabled}
                  id="requirements-support-needed"
                  onClick={() => setIsSupportNeededDialog(true)}
                  text={t('requirements.overview.support-needed')}
                />
                {isCopyButtonDisabled ? (
                  <RoundButton
                    className={styles.RoundButton}
                    disabled={isCopyButtonDisabled}
                    icon="di icon-datei-doppelt-kopieren"
                    id="RequirementList-copy-button"
                    onClick={handleCopyRequirement}
                  />
                ) : (
                  <TooltipComponent
                    content={t('copyRequirement')}
                    cssClass="mbc-tooltip nepos-tooltip"
                    position="BottomCenter"
                    showTipPointer={false}
                  >
                    <RoundButton
                      className={styles.RoundButton}
                      disabled={isFreezed || isCopyButtonDisabled}
                      icon="di icon-datei-doppelt-kopieren"
                      id="RequirementList-copy-button"
                      onClick={handleCopyRequirement}
                    />
                  </TooltipComponent>
                )}
                <RoundButton
                  className={styles.RoundButton}
                  disabled={isFreezed || isDeleteButtonDisabled}
                  icon="di icon-muelleimer-loeschen"
                  id="RequirementList-trash-button"
                  onClick={() => setShowDeleteRequirementDialog(true)}
                />
                <RoundButton
                  className={styles.RoundButton}
                  disabled={isFreezed || !columnsConfig}
                  icon="di icon-diskette-speichern"
                  id="RequirementList-save-button"
                  onClick={() => saveColumnsConfig(columnsConfig as ColumnsConfig)}
                />
                <RoundButton
                  className={styles.RoundButton}
                  icon="di icon-zahnrad-einstellungen"
                  id="RequirementList-settings-button"
                  onClick={() => setIsColumnsSelectionPanelOpen(!isColumnsSelectionPanelOpen)}
                  ref={buttonRef}
                />
                {isColumnsSelectionPanelOpen && (
                  <DropdownPanel
                    className={styles.SettingsPanel}
                    close={() => setIsColumnsSelectionPanelOpen(false)}
                    horizontalPosition={Coordinate.RIGHT}
                    parentRef={buttonRef}
                    width={200}
                  >
                    <ul className={styles.ColumnsList}>
                      {requirementsColumns
                        .filter((column) => column.id !== 'supportNeeded')
                        .map((option) => (
                          <li className={styles.ColumnsItem} id={option.id} key={option.id}>
                            <CheckboxNEPOS
                              checked={columnsSelected.some((elem) => option.id === elem)}
                              handleCheck={() => handleDropdownPanelCheck(option.id)}
                              label={t([`requirements.${option.id}`, option.id])}
                            />
                          </li>
                        ))}
                    </ul>
                    <DialogFooter buttons={dropdownPanelButtons} extraClass={styles.ColumnsFooter} />
                  </DropdownPanel>
                )}
              </div>
            </div>
            {columnsToShow && (
              <Table
                columns={columnsToShow}
                getPage={(newPage) => {
                  setPage(newPage as any);
                }}
                getSorted={sortTable}
                horizontallyScrollable
                isLoading={isLoading}
                onCheck={setSelectedItems}
                page={page}
                pageSize={REQUIREMENT_PAGE_SIZE}
                pagination={Pagination.PAGES}
                prefix="requirements."
                rows={results as unknown as Requirement[]}
                sorting={sorting}
                totalPages={totalPages}
                variant={TableVariant.REQUIREMENT}
              />
            )}
          </div>
        </div>
      </div>
      {showDeleteRequirementDialog && (
        <DeleteDialog
          handleClick={(confirmationValue) => handleConfirmDelete(confirmationValue)}
          objectName={selectedItem?.title || ''}
          prefix={DeleteDialogPrefixes.REQUIREMENT}
        />
      )}
      {showConfirmRetrieveDialog && (
        <DialogNEPOS
          dialog={{
            buttons: dialogButtonsRetrieveConfirmDialog,
            title: t('requirement.dialog.confirm.retrieve.title'),
            type: DialogType.Info,
          }}
          extraClass="Modal"
        >
          {t('requirement.dialog.confirm.retrieve.message')}
        </DialogNEPOS>
      )}
      {showRetrieveDialog && (
        <DialogNEPOS
          dialog={{
            buttons: [
              {
                buttonStyle: BUTTON_PRIMARY,
                content: t('ok'),
                handleClick: () => {
                  setShowRetrieveDialog(false);
                },
                key: 'retrieve-button-submit',
              },
            ],
            title: t('requirement.dialog.confirm.retrieve.title'),
            type: DialogType.Info,
          }}
          extraClass="Modal"
        >
          {t('requirement.dialog.retrieve.message')}
        </DialogNEPOS>
      )}
      {showProcessFilterDialog && (
        <DialogNEPOS dialog={processFilterDialog}>
          {processLevelColumns && (
            <>
              <SearchNEPOS id="requirements-process-level-filter" isAsync searching={setSearchText} />
              <ProcessLevelDatabaseTable
                columns={processLevelColumns}
                isTreeStructure
                onCheck={(ids) => setSelectedProcessId(ids[0])}
                rows={processLevelResults}
                searchText={searchText}
                selectedRows={[selectedProcessId]}
              />
            </>
          )}
        </DialogNEPOS>
      )}
      {isSupportNeededDialog && (
        <DialogNEPOS dialog={supportDialog} extraClass="Modal">
          {t('diagram.supportMessage')}
        </DialogNEPOS>
      )}
    </Page>
  );
};

export default RequirementList;
