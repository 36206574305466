import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { E2E, ENVIRONMENTS } from 'assets/constants/constants';
import { CORE_PROCESSES } from 'assets/constants/landscapeProcesses';
import useAuth from 'hooks/useAuth';

import styles from './CoreProcesses.module.scss';

const CoreProcesses = ({ language, e2e }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { env } = useAuth();
  const circularTextRef = useRef(null);
  const processNameRef = useRef(null);
  const radius = {
    container: 50,
    inner: 42.5,
    outer: 45,
  };

  const LinkWrapper = ({ process, children }) => {
    const id = e2e ? process.id[E2E] : process.id[env] || process.id[ENVIRONMENTS.PRO];
    const e2eLandscape = e2e ? `/${e2e}` : '';

    if (typeof id === 'number') {
      return (
        <Link
          className={styles.Link}
          to={`/${location.pathname.includes('published') ? 'published' : 'diagram'}${e2eLandscape}/${id}`}
        >
          {children}
        </Link>
      );
    }

    return (
      <a className={styles.Link} href={id} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  };

  return (
    <div className={styles.Content}>
      <div className={styles.Processes}>
        <svg className={styles.CircularText} ref={circularTextRef} viewBox="0 0 100 100">
          <path
            d={`
              M ${radius.container} ${radius.container}
              m -${radius.inner}, 0
              a ${radius.inner},${radius.inner} 0 1,1 ${radius.inner * 2},0
              a ${radius.inner},${radius.inner} 0 1,1 -${radius.inner * 2},0`}
            fill="none"
            id="circularPath"
            transform={`rotate(-30 ${radius.container} ${radius.container})`}
          />
          <path
            d={`
              M ${radius.container} ${radius.container}
              m -${radius.outer}, 0
              a ${radius.outer},${radius.outer} 0 1,0 ${radius.outer * 2},0
              a ${radius.outer},${radius.outer} 0 1,0 -${radius.outer * 2},0`}
            fill="none"
            id="outerCircularPath"
            transform={`rotate(-30 ${radius.container} ${radius.container})`}
          />
          <text>
            <textPath startOffset="16.66%" textAnchor="middle" xlinkHref="#circularPath">
              <LinkWrapper process={CORE_PROCESSES[0]} ref={processNameRef}>
                {CORE_PROCESSES[0].processName[language]}
              </LinkWrapper>
            </textPath>
          </text>
          <text>
            <textPath startOffset="50%" textAnchor="middle" xlinkHref="#circularPath">
              <LinkWrapper process={CORE_PROCESSES[1]}>{CORE_PROCESSES[1].processName[language]}</LinkWrapper>
            </textPath>
          </text>
          <text>
            <textPath startOffset="16.66%" textAnchor="middle" xlinkHref="#outerCircularPath">
              <LinkWrapper process={CORE_PROCESSES[2]}>{CORE_PROCESSES[2].processName[language]}</LinkWrapper>
            </textPath>
          </text>
          <polygon fill="white" points="47,53 50,47 53,53" transform="rotate(10 100 26) translate(-50.3, 3)" />
          <polygon fill="white" points="47,53 50,47 53,53" transform="rotate(150 53 52) translate(-50, -2)" />
          <polygon fill="white" points="47,53 50,47 53,53" transform="rotate(270 50 50) translate(-50.2, 0)" />
          <polygon fill="white" points="44,50 50,0 56,50" transform="rotate(0 50 50)" />
          <polygon fill="white" points="44,50 50,0 56,50" transform="rotate(120 50 50)" />
          <polygon fill="white" points="44,50 50,0 56,50" transform="rotate(240 50 50)" />
        </svg>
        <div className={styles.Title}>
          <div className={styles.Wrapper}>
            {CORE_PROCESSES.map((process, index) => (
              <a
                href={`https://social.intra.corpintra.net/people/${process.imgs[0].code}`}
                key={process.imgs[0].code}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className={`${styles.UserImage} ${styles[`Image${index}`]}`}>
                  <img alt={process.imgs[0].userName} src={process.imgs[0].url} />
                </div>
              </a>
            ))}
            <span>{t('landscape.coreProcesses', { lng: language })}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreProcesses;
