import React from 'react';

import { PROCESS_GROUPS } from '../../../../assets/constants/constants';
import styles from './ProcessesRow.module.scss';

const ProcessesRow = ({ isHeader, element, handleRowClick, sortTable, fieldToOrder, direction, extraClick, selectedTab }) => {
  const getSortedClass = (field) => (fieldToOrder === field ? styles[direction] : '');
  let processesColumns = '';
  let processesStyle = '';

  if (selectedTab === PROCESS_GROUPS.MY_PROCESSES) {
    processesStyle = styles.Processes;
    processesColumns = isHeader ? (
      <>
        <th
          className={`${styles.ProcessName} ${getSortedClass('processName')}`}
          id="processes-header-process-name"
          onClick={() => sortTable('processName')}
        >
          {element.processName}
        </th>
        <th className={`${styles.Role} ${getSortedClass('role')}`} onClick={() => sortTable('role')}>
          {element.role}
        </th>
        <th className={`${styles.Version} ${getSortedClass('version')}`} onClick={() => sortTable('version')}>
          {element.version}
        </th>
        <th className={`${styles.ValidFrom} ${getSortedClass('validFrom')}`} onClick={() => sortTable('validFrom')}>
          {element.validFrom}
        </th>
      </>
    ) : (
      <>
        <td className={styles.Role}>{element.role}</td>
        <td className={styles.Version}>{element.version}</td>
        <td className={styles.ValidFrom}>{element.validFrom}</td>
      </>
    );
  } else {
    processesColumns = isHeader ? (
      <>
        <th
          className={`${styles.ProcessName} ${getSortedClass('PROCESS_NAME')}`}
          id="processes-header-process-name"
          onClick={() => sortTable('PROCESS_NAME')}
        >
          {element.processName}
        </th>
        <th
          className={`${styles.Version} ${getSortedClass('VERSION')}`}
          id="processes-header-version"
          onClick={() => sortTable('VERSION')}
        >
          {element.version}
        </th>
        <th
          className={`${styles.Author} ${getSortedClass('AUTHOR')}`}
          id="processes-header-author"
          onClick={() => sortTable('AUTHOR')}
        >
          {element.author}
        </th>
        <th className={styles.FavIcon}>{}</th>
      </>
    ) : (
      <>
        <td className={styles.Version}>{element.version}</td>
        <td className={styles.Author}>{element.author}</td>
        <td className={styles.FavIcon} onClick={(e) => extraClick(element, e)}>
          <i className="di icon-stern-favorit" />
        </td>
      </>
    );
  }

  if (isHeader) {
    return <tr className={`${styles.Header} ${styles.ListItem} ${processesStyle}`}>{processesColumns}</tr>;
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  return (
    <tr className={`${styles.ListItem} ${processesStyle}`} onClick={() => handleRowClick(element.id)}>
      <td className={styles.ProcessName}>{element.processName}</td>
      {processesColumns}
    </tr>
  );
};

export default ProcessesRow;
