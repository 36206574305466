import { useEffect, useRef, useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY, BUTTON_SECONDARY, CREATE, EDIT, ADMINISTRATOR } from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import * as Utils from 'assets/js/Utils';
import { getFormattedDate } from 'assets/js/Utils';
import Page from 'components/Page/Page';
import ButtonNEPOS from 'components/UI/ButtonNEPOS/ButtonNEPOS';
import CheckboxNEPOS from 'components/UI/CheckboxNEPOS/CheckboxNEPOS';
import DeleteDialog from 'components/UI/DeleteDialog/DeleteDialog';
import Dialog from 'components/UI/Dialog/Dialog';
import DialogFooter from 'components/UI/DialogFooter/DialogFooter';
import DialogHeader from 'components/UI/DialogHeader/DialogHeader';
import DropdownPanel from 'components/UI/DropdownPanel/DropdownPanel';
import IconButtonDropdown from 'components/UI/IconButtonDropdown/IconButtonDropdown';
import Pagination from 'components/UI/Pagination/Pagination';
import RoundButton from 'components/UI/RoundButton/RoundButton';
import SearchNEPOS from 'components/UI/SearchNEPOS/SearchNEPOS';
import Spinner from 'components/UI/Spinner/Spinner';
import TableList from 'components/UI/TableList/TableList';
import TableRow from 'components/UI/TableList/TableRow/TableRow';
import TextButton from 'components/UI/TextButton/TextButton';
import { DiagramActionTypes } from 'contexts/Diagram/DiagramContext';
import useAuth from 'hooks/useAuth';
import useDiagramContext from 'hooks/useDiagramContext';
import useFeatureFlags from 'hooks/useFeatureFlags';
import usePagination from 'hooks/usePagination';
import useProcessLevelDatabase from 'hooks/useProcessLevel';
import useProcessLevelDatabaseContext from 'hooks/useProcessLevelContext';
import ProcessLevelDatabaseTable from 'pages/ProcessLevelDatabase/ProcessLevelDatabaseTable';
import ProcessLevelFormDialog from 'pages/ProcessLevelDatabase/ProcessLevelFormDialog';
import { exportProcess, getHistoric } from 'services/processLevelDatabase';
// TODO: Change saveColumnsConfig service from requirement services to process level services when available
import { saveColumnsConfig } from 'services/requirement';
import titleService from 'services/titleService';
import { Coordinate } from 'types/diagram';
import { DeleteDialogPrefixes } from 'types/dialogs';
import {
  ColumnsConfig,
  ProcessLevelFormatted,
  ProcessCategories,
  ProcessLevelFilters,
  ProcessWaves,
  HistoricData,
} from 'types/processLevel';
import { TableColumn } from 'types/tables';
import { MultiDepartmentCatalogUser } from 'types/user';

import styles from './ProcessLevelDatabase.module.scss';

const initialColumnsToShow: TableColumn<ProcessLevelFormatted>[] = [
  { id: 'title', style: styles.Title },
  { id: 'wave', style: styles.Id },
  { id: 'level', style: styles.Id },
  { id: 'category', style: styles.Status },
  { id: 'processResponsibles', style: styles.Person },
  { id: 'processCoordinators', style: styles.Person },
  { id: 'pacemakers', style: styles.Person },
];

const ProcessLevelDatabase = () => {
  const { t } = useTranslation();
  const { dispatch } = useDiagramContext();
  const {
    fetchProcessLevelForm,
    fetchAllProcesses,
    updateProcessLevel,
    createNewProcessLevel,
    deleteExistingProcessLevel,
    getUsersWithCatalog,
  } = useProcessLevelDatabase();
  const { savedColumnsConfig, isLoading } = useProcessLevelDatabaseContext();
  const { checkRole } = useAuth();
  const buttonRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showProcessFormDialog, setShowProcessFormDialog] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [isColumnsSelectionPanelOpen, setIsColumnsSelectionPanelOpen] = useState(false);
  const [columnsSelected, setColumnsSelected] = useState<string[]>([]);
  const [columnsConfig, setColumnsConfig] = useState<ColumnsConfig>();
  const [columnsToShow] = useState<TableColumn<ProcessLevelFormatted>[]>(initialColumnsToShow);
  const [selectedItem, setSelectedItem] = useState<ProcessLevelFormatted>();
  const [results, setResults] = useState<ProcessLevelFormatted[]>();
  const [filteredResults, setFilteredResults] = useState<ProcessLevelFormatted[]>();
  const [filters, setFilters] = useState({});
  const [searchText, setSearchText] = useState<string>('');
  const [categoryFilters, setCategoryFilters] = useState<string[]>([]);
  const [waveFilters, setWaveFilters] = useState<string[]>([]);
  const [changeHistoricDialog, setChangeHistoricDialog] = useState<boolean>(false);
  const [dataCatalog, setDataCatalog] = useState<MultiDepartmentCatalogUser[]>([]);
  const [showDeleteProcessLevelDialog, setShowDeleteProcessLevelDialog] = useState<boolean>(false);
  titleService.updatePageTitle('Process level database');
  const { isFreezed } = useFeatureFlags();

  const handleFiltersChange = (params) => {
    const filterKey = Object.keys(params)[0];
    let allFilters = filters;
    let filteredData: ProcessLevelFormatted[] = [];

    if (params[filterKey]) {
      allFilters = { ...filters, ...params };
    } else {
      delete allFilters[filterKey];
    }

    setFilters(allFilters);

    const allFilterKeys = Object.keys(allFilters);

    const filtersValues: string[] = Object.values(allFilters);
    let firstArray: ProcessLevelFormatted[] = [];

    for (let index = 0; index < filtersValues.length; index++) {
      const elements = filtersValues[index]
        .split(',')
        .map((key) => key.split('_')[allFilterKeys[index] === ProcessLevelFilters.WAVE ? 1 : 0]);

      if (index === 0) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        elements.forEach((value: string) => {
          if (!value) return;
          results?.forEach((result) => {
            if (result[allFilterKeys[0].toLowerCase()].toLowerCase().includes(value.toLowerCase())) {
              filteredData.push(result);
            }
            firstArray = filteredData;
            return filteredData;
          });
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        elements.forEach((value: string, i) => {
          if (!value) return;

          const allResultsFiltered =
            results?.filter((elem) => elem[allFilterKeys[index].toLowerCase()].toLowerCase().includes(value.toLowerCase())) || [];

          const intersection = allResultsFiltered.filter((element) => firstArray.includes(element));

          if (i === 0) filteredData = intersection;
          else {
            intersection.forEach((ele) => filteredData.push(ele));
          }
          return filteredData;
        });
      }
    }

    setFilteredResults(allFilterKeys.length > 0 ? filteredData : results);
  };

  const getAllProcess = useCallback(() => {
    fetchAllProcesses(false)
      .then((res) => {
        setResults(res?.finalResults);
        setFilteredResults(res?.finalResults);
        setDataCatalog(res?.catalog.USER as MultiDepartmentCatalogUser[]);
      })
      .catch((err) => handleServiceError(err));
  }, [fetchAllProcesses]);

  useEffect(() => {
    getAllProcess();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dialogType === '') return;
    fetchProcessLevelForm(dialogType);
  }, [dialogType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setColumnsConfig(savedColumnsConfig);
  }, [savedColumnsConfig]);

  useEffect(() => {
    if (columnsConfig)
      setColumnsSelected(
        Object.keys(columnsConfig as ColumnsConfig).filter((column) => columnsConfig[column as keyof ColumnsConfig] === true),
      );
  }, [columnsConfig]);

  useEffect(() => {
    if (!results || results.length === 0 || !selectedItems || selectedItems.length === 0) {
      setSelectedItem(undefined);
      return;
    }

    const searchForSelected = (processArray: ProcessLevelFormatted[]): ProcessLevelFormatted | undefined => {
      const parentLevelResult = processArray.find((element) => element.id.toString() === selectedItems[0]);
      if (parentLevelResult) return parentLevelResult;

      const childrenLevelResult = processArray.map((process) => {
        if (!process.children) return;
        return searchForSelected(process.children);
      });

      const allResults = [parentLevelResult, ...childrenLevelResult].filter(Boolean);
      const selected = [...new Set<ProcessLevelFormatted>(allResults as ProcessLevelFormatted[])][0];

      return selected;
    };
    setSelectedItem(searchForSelected(results));
  }, [results, selectedItems]);

  const formatResult = (elem) => {
    if (
      getUsersWithCatalog(elem.currentValue, dataCatalog, false).length > 0 &&
      getUsersWithCatalog(elem.previousValue, dataCatalog, false).length > 0
    ) {
      return `${getUsersWithCatalog(elem.previousValue, dataCatalog, false)} ${t(`was replaced by`)} ${getUsersWithCatalog(
        elem.currentValue,
        dataCatalog,
        false,
      )}`;
    }
    if (getUsersWithCatalog(elem.previousValue, dataCatalog, false).length < 1) {
      return `${getUsersWithCatalog(elem.currentValue, dataCatalog, false)} ${t(`was inserted`)}`;
    }
    return `${getUsersWithCatalog(elem.previousValue, dataCatalog, false)} ${t(`was removed`)}`;
  };

  const getResults = useCallback(
    (params) => {
      return getHistoric(selectedItems[0], params.page, params.size).then((response) => {
        const { changelog } = response.data;
        const historicResults: HistoricData = changelog.map(
          (elem: {
            id: string;
            action: string;
            remark: string;
            previousValue: string[];
            currentValue: string[];
            modificationDate: string;
            editorCode: string;
          }) => {
            return {
              modificationDate: getFormattedDate(elem.modificationDate),
              remark: t(`attributes.${elem.remark}.name`),
              description: formatResult(elem),
              author: getUsersWithCatalog([elem.editorCode], dataCatalog, false).toString(),
              id: elem.id,
            };
          },
        );
        return {
          numberOfPages: response.data.numberOfPages,
          results: historicResults as HistoricData,
          totalResults: response.data.totalDataSet,
        };
      });
    },
    [changeHistoricDialog, selectedItems], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const pagination = usePagination({
    getResults: changeHistoricDialog && selectedItems.length && getResults,
    pageSize: 10,
  });
  const { page, setPage, totalPages, results: historicResult } = pagination;

  const handleDropdownPanelCheck = (item: string) => {
    let newColumnsSelected = [...columnsSelected];

    if (columnsSelected.some((elem) => item === elem)) {
      newColumnsSelected = columnsSelected.filter((el) => el !== item);
    } else {
      newColumnsSelected = [...columnsSelected, item];
    }

    setColumnsSelected(newColumnsSelected);
  };
  const handleOpenChangeHistory = () => {
    setChangeHistoricDialog(true);
  };

  const handleOpenCreateEditDialog = (type: string, isShown: boolean): void => {
    setDialogType(type);
    setShowProcessFormDialog(isShown);
  };

  const handleProcessFormConfirm = async (values, linkedLevel) => {
    setShowProcessFormDialog(false);
    if (dialogType === CREATE) {
      createNewProcessLevel(values, linkedLevel).then(() => getAllProcess());
    } else {
      updateProcessLevel(values, selectedItem, linkedLevel).then(() => getAllProcess());
    }
    setSelectedItems([]);
  };

  const dropdownPanelButtons = [
    {
      id: 'processLevelDatabase-columns-apply',
      key: 'processLevelDatabase-columns-apply',
      handleClick: () => {
        setIsColumnsSelectionPanelOpen(false);
      },
      content: t('apply'),
      buttonStyle: BUTTON_PRIMARY,
      disabled: !columnsSelected,
    },
    {
      id: 'processLevelDatabase-columns-reset',
      key: 'processLevelDatabase-columns-reset',
      handleClick: () => {
        setIsColumnsSelectionPanelOpen(false);
      },
      content: t('reset'),
      buttonStyle: BUTTON_SECONDARY,
      disabled: false,
    },
  ];

  const handleConfirmDelete = useCallback(
    async (confirmationString?: string) => {
      setShowDeleteProcessLevelDialog(false);
      if (!confirmationString) return;
      const deleteProcess = await deleteExistingProcessLevel(selectedItem!!.id, confirmationString);
      if (deleteProcess) {
        getAllProcess();
      }
      setSelectedItems([]);
    },
    [deleteExistingProcessLevel, selectedItem, getAllProcess],
  );

  const editionActions = [
    {
      id: 'processLevelDatabase-change-history',
      onClick: () => handleOpenChangeHistory(),
      disabled: selectedItems.length !== 1,
      text: 'tool.history',
    },
    {
      id: 'processLevelDatabase-edit-button',
      onClick: () => handleOpenCreateEditDialog(EDIT, true),
      disabled: isFreezed || selectedItems.length !== 1,
      text: 'tool.edit',
    },
  ];

  return (
    <Page>
      <div className={styles.Wrapper}>
        <div className={styles.Container}>
          <div className={styles.Header}>
            <SearchNEPOS id="processLevelDatabase-list-search" isAsync searching={(text: string) => setSearchText(text)} />
            <IconButtonDropdown
              buttonStyle={styles.FilterButton}
              icon="di icon-filter"
              id="category-filter"
              isCheckboxDropdown
              onChange={(value: string) =>
                Utils.handleFilters(value, ProcessLevelFilters.CATEGORY, setCategoryFilters, categoryFilters, handleFiltersChange)
              }
              options={Object.keys(ProcessCategories).map((option: string) => {
                return { value: option, label: t(`attributes.CATEGORY.options?.${option}`) };
              })}
              title={t('category')}
            />
            <IconButtonDropdown
              buttonStyle={styles.FilterButton}
              icon="di icon-filter"
              id="wave-filter"
              isCheckboxDropdown
              onChange={(value: string) =>
                Utils.handleFilters(value, ProcessLevelFilters.WAVE, setWaveFilters, waveFilters, handleFiltersChange)
              }
              options={Object.keys(ProcessWaves).map((option: string, index) => {
                return { value: option, label: t('waveOption', { number: index + 1 }) };
              })}
              title={t('wave')}
            />
            <div className={styles.ButtonsContainer}>
              <ButtonNEPOS
                className={styles.DownloadButton}
                disabled={isLoading}
                handleClick={() =>
                  exportProcess().then((res) => {
                    Utils.downloadBlob(res.data, res.headers, 'report.xlsx');
                  })
                }
                icon="di icon-herunterladen"
                id="processLevelDatabase-download"
              >
                {t('download')}
              </ButtonNEPOS>
              <ButtonNEPOS
                disabled={isFreezed || isLoading}
                handleClick={() => handleOpenCreateEditDialog(CREATE, true)}
                icon="di icon-plus-hinzufuegen-klein"
                id="processLevelDatabase-create-process"
              >
                {t('createNewProcess')}
              </ButtonNEPOS>
            </div>
          </div>
          <div className={styles.Body}>
            <div className={`${styles.ButtonsBar} ${selectedItems.length ? styles.Checked : ''}`}>
              {!!selectedItems.length && (
                <span>{`${selectedItems.length} ${selectedItems.length > 1 ? t('entriesSelected') : t('entrySelected')}`}</span>
              )}
              <div className={styles.ButtonWrapper}>
                {editionActions.map((action) => (
                  <TextButton
                    disabled={action.disabled}
                    id={action.id}
                    key={`key-${action.id}`}
                    onClick={action.onClick}
                    text={t(action.text)}
                  />
                ))}
                <RoundButton
                  className={styles.RoundButton}
                  disabled={selectedItems.length !== 1 || !checkRole(ADMINISTRATOR)}
                  icon="di icon-muelleimer-loeschen"
                  id="processLevelDatabase-trash-button"
                  onClick={() =>
                    selectedItem?.children.length === 0
                      ? setShowDeleteProcessLevelDialog(true)
                      : dispatch({
                          type: DiagramActionTypes.SET_ERROR,
                          payload: { title: t('error'), message: t('deleteParentProcessError') },
                        })
                  }
                />
                <RoundButton
                  className={styles.RoundButton}
                  disabled={!columnsConfig}
                  icon="di icon-diskette-speichern"
                  id="processLevelDatabase-save-button"
                  onClick={() => saveColumnsConfig(columnsConfig as ColumnsConfig)}
                />
                <RoundButton
                  className={styles.RoundButton}
                  disabled
                  icon="di icon-zahnrad-einstellungen"
                  id="processLevelDatabase-settings-button"
                  onClick={() => setIsColumnsSelectionPanelOpen(!isColumnsSelectionPanelOpen)}
                  ref={buttonRef}
                />
                <RoundButton
                  className={styles.RoundButton}
                  disabled
                  icon="di icon-export"
                  id="processLevelDatabase-export-button"
                />
                {isColumnsSelectionPanelOpen && (
                  <DropdownPanel
                    className={styles.SettingsPanel}
                    close={() => setIsColumnsSelectionPanelOpen(false)}
                    horizontalPosition={Coordinate.RIGHT}
                    parentRef={buttonRef}
                    width={200}
                  >
                    <ul className={styles.ColumnsList}>
                      {initialColumnsToShow.map((option) => (
                        <li className={styles.ColumnsItem} id={option.id} key={option.id}>
                          <CheckboxNEPOS
                            checked={columnsSelected.some((elem) => option.id === elem)}
                            handleCheck={() => handleDropdownPanelCheck(option.id)}
                            label={t([`requirements.${option.id}`, option.id])}
                          />
                        </li>
                      ))}
                    </ul>
                    <DialogFooter buttons={dropdownPanelButtons} extraClass={styles.ColumnsFooter} />
                  </DropdownPanel>
                )}
              </div>
            </div>
            {!isLoading && columnsToShow ? (
              <ProcessLevelDatabaseTable
                columns={columnsToShow}
                isLoading={isLoading}
                isTreeStructure
                multiselect
                onCheck={setSelectedItems}
                rows={filteredResults}
                searchText={searchText}
              />
            ) : (
              <div className={styles.Spinner}>
                <Spinner defaultSpinner isVisible />
              </div>
            )}
          </div>
        </div>
      </div>
      {changeHistoricDialog && (
        <Dialog className={`${styles.DialogContainer} ${styles.ContainerMinSize}`}>
          <DialogHeader closeIcon handleClose={() => setChangeHistoricDialog(false)}>
            {t('processLevelDatabase-change-historic')}
          </DialogHeader>
          <div className={styles.TableWrapper}>
            <TableList
              getPage={(newPage) => {
                setPage(newPage as number);
              }}
              header={[
                { name: t('history.table.date'), className: 'ModificationDate' },
                { name: t('history.table.action') },
                { name: t('history.table.description'), className: 'Description' },
                { name: t('history.table.author'), className: 'Author' },
              ]}
              isDepartmentView
              list={historicResult || []}
              page={page}
              pagination={pagination}
              RowComponent={TableRow}
              sortTable={false}
              totalPages={totalPages}
            />
          </div>
          {!pagination.isLoading && !!totalPages && totalPages > 1 && (
            <div className={styles.PagesSection}>
              <Pagination page={page} pageClick={setPage} totalPages={totalPages} />
            </div>
          )}
        </Dialog>
      )}
      {showProcessFormDialog && (
        <ProcessLevelFormDialog
          defaultValues={dialogType === EDIT ? selectedItem : undefined}
          onClose={() => setShowProcessFormDialog(false)}
          onConfirm={handleProcessFormConfirm}
          processes={results}
        />
      )}
      {showDeleteProcessLevelDialog && selectedItem && (
        <DeleteDialog
          handleClick={(confirmationString) => handleConfirmDelete(confirmationString)}
          objectName={selectedItem.title}
          prefix={DeleteDialogPrefixes.PROCESS_LEVEL}
        />
      )}
    </Page>
  );
};

export default ProcessLevelDatabase;
