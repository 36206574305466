import React, { useState, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import ScopesExplorer from 'components/ScopesExplorer/ScopesExplorer';
import TitleBar from 'components/TitleBar/TitleBar';
import Pagination from 'components/UI/Pagination/Pagination';
import Search from 'components/UI/Search/Search';
import DepartmentDiagramRow from 'components/UI/TableList/DepartmentDiagramRow/DepartmentDiagramRow';
import TableList from 'components/UI/TableList/TableList';
import TabSelector from 'components/UI/TabSelector/TabSelector';
import useAuth from 'hooks/useAuth';
import useError from 'hooks/useError';
import usePagination from 'hooks/usePagination';
import documentationService from 'services/documentationService';
import titleService from 'services/titleService';
import userService from 'services/userService';

import styles from './DepartmentView.module.scss';

const DepartmentView = () => {
  const { t } = useTranslation();
  const { handleServiceError } = useError();
  const { userInfo } = useAuth();
  const [department, setDepartment] = useState();
  const [tabSelected, setTabSelected] = useState('fromDepartment');
  titleService.updatePageTitle('Department view');

  const getResults = useCallback(
    (params) =>
      documentationService
        .getDepartmentViewProcesses(department?.departmentId, tabSelected === 'fromDepartment', params)
        .then((response) => ({
          numberOfPages: response.data.numberOfPages,
          results: response.data.results,
          totalResults: response.data.totalResults,
        })),
    [department?.departmentId, tabSelected],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!userInfo) return;
    setDepartment({ departmentId: userInfo?.departmentId });
  }, [userInfo?.departmentId]); // eslint-disable-line react-hooks/exhaustive-deps

  const pagination = usePagination({ getResults: department?.departmentId && getResults });
  const { handleSearchChange, page, results, setPage, sorting, sortTable, totalPages, totalResults } = pagination;

  const toggleFavorites = async (element, setFav, fav) => {
    try {
      setFav(!fav);

      if (!fav) {
        // No then needed
        await userService.addToFavorites(element.idProcess);
      } else {
        // No then needed
        await userService.removeFavorite(element.idProcess);
      }
    } catch (error) {
      setFav(fav);
      handleServiceError(error);
    }
  };

  const tableHeader = {
    nameMap: t('departmentView.table.NAME'),
    author: t('departmentView.table.AUTHOR'),
    creationDate: t('departmentView.table.CREATED'),
    type: t('departmentView.table.TYPE'),
  };

  return (
    <>
      <TitleBar />
      <div className={styles.Department}>
        <div className={styles.ScopesExplorer}>
          <ScopesExplorer departmentId={department?.departmentId} setDepartment={setDepartment} />
        </div>
        <div className={styles.Content}>
          {/* TODO: Ahora mismo el nombre del departamento estaría hardcodeado */}
          <div className={styles.Name}>{department?.displayName}</div>
          {results?.length > 0 && <div className={styles.Entries}>{`${totalResults} ${t('departmentView.results')}`}</div>}
          <div className={styles.Results}>
            <div className={styles.SearchSection}>
              <Search disabled={pagination.isLoading} isAsync searching={handleSearchChange} />
              <TabSelector
                className={styles.Tabs}
                handleClick={setTabSelected}
                options={[
                  {
                    name: `${t('departmentView.fromDepartment')}`,
                    id: 'fromDepartment',
                    isValid: true,
                    tooltip: `${t('departmentView.tooltipfromDepartment')}`,
                  },
                  {
                    name: `${t('departmentView.validForDepartment')}`,
                    id: 'validForDepartment',
                    isValid: true,
                    tooltip: `${t('departmentView.tooltipvalidForDepartment')}`,
                  },
                ]}
                selectedOption={tabSelected}
              />
            </div>
            <TableList
              direction={sorting.order}
              extraClick={toggleFavorites}
              fieldToOrder={sorting.filter}
              header={tableHeader}
              isDepartmentView
              isLoading={pagination.isLoading}
              list={results}
              noToolbar
              RowComponent={DepartmentDiagramRow}
              sortTable={sortTable}
            />
            {results?.length > 0 && totalPages > 1 && (
              <div className={styles.PagesSection}>
                <Pagination page={page} pageClick={setPage} totalPages={totalPages} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentView;
