import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { STATUS } from 'assets/constants/constants';
import { getDangerouslySetInnerHTML } from 'assets/js/Utils';
import { InputOutputCatalog, IOCatalogHeader } from 'types/administration';

import styles from './InputOutput.module.scss';

type Props = {
  direction: string;
  element: InputOutputCatalog | IOCatalogHeader;
  fieldToOrder: string;
  handleRowClick: (id: string) => {};
  handleCheckAll: () => {};
  isChecked: boolean;
  isDisabled: boolean;
  isHeader: boolean;
  isRadio: boolean;
  sortTable: (objectDescription: string) => {};
  isNeposQIGroup: boolean;
  isNotSwimlane: boolean;
  isNotInputOutput: boolean;
};

const InputOutputRow = (props: Props) => {
  const {
    direction,
    element,
    fieldToOrder,
    handleRowClick,
    handleCheckAll,
    isChecked,
    isDisabled,
    isHeader,
    isRadio,
    sortTable,
    isNeposQIGroup,
    isNotSwimlane,
    isNotInputOutput,
  } = props;

  const { t } = useTranslation();
  const checked = isChecked ? styles.Checked : '';
  const [isContainedInExpanded, setIsContainedInExpanded] = useState(false);
  const getSortedClass = (field: string) => (fieldToOrder === field ? styles[direction] : '');

  const handleExpandButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setIsContainedInExpanded(!isContainedInExpanded);
    event.stopPropagation();
  };

  if (isHeader) {
    return (
      <tr className={`${styles.Header} ${checked} ${styles.ListItem}`}>
        <th className={styles.Checkbox}>
          {!isRadio && (isNeposQIGroup || (isNotSwimlane && isNotInputOutput)) && (
            <span className={`${styles.Checkmark} ${isDisabled ? styles.Disabled : ''}`} onClick={handleCheckAll} />
          )}
        </th>
        <th className={`${styles.Name} ${getSortedClass('name')}`} onClick={() => sortTable('name')}>
          {element.name}
        </th>
        <th className={`${styles.Description} ${getSortedClass('description')}`} onClick={() => sortTable('description')}>
          {element.description}
        </th>
        <th
          className={`${styles.Responsible} ${getSortedClass('responsiblePerson')}`}
          onClick={() => sortTable('responsiblePerson')}
        >
          {element.responsiblePerson}
        </th>
        <th className={`${styles.Area} ${getSortedClass('department')}`} onClick={() => sortTable('department')}>
          {element.department}
        </th>
        <th className={`${styles.Status} ${styles.NonSortable}`}>{element.status}</th>
        <th className={`${styles.QIGroup} ${getSortedClass('responsibleArea')}`} onClick={() => sortTable('responsibleArea')}>
          {element.qiGroup}
        </th>
        <th className={`${styles.Created} ${getSortedClass('creationDate')}`} onClick={() => sortTable('creationDate')}>
          {element.creation}
        </th>
        <th className={`${styles.ContainedIn} ${styles.NonSortable}`}>{(element as IOCatalogHeader).containedIn}</th>
      </tr>
    );
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  return (
    <tr
      className={`${styles.ListItem} ${checked}`}
      key={(element as InputOutputCatalog).id}
      onClick={() => handleRowClick((element as InputOutputCatalog).id)}
      style={{ pointerEvents: isDisabled ? 'none' : 'all' }}
    >
      <td className={styles.Checkbox}>
        {(isNeposQIGroup || (isNotSwimlane && isNotInputOutput)) && (
          <span className={isRadio ? styles.InputRadio : styles.Checkmark} />
        )}
      </td>
      <td className={styles.Name}>{element.name}</td>
      {/* eslint-disable-next-line react/no-danger */}
      <td className={styles.Description} dangerouslySetInnerHTML={getDangerouslySetInnerHTML(element.description)} />
      <td className={styles.Responsible}>{element.responsiblePerson}</td>
      <td className={styles.Area}>{element.department}</td>
      <td className={styles.Status}>{t(`${element.status}`)}</td>
      <td className={styles.QIGroup}>{element.qiGroup}</td>
      <td className={styles.Created}>{element.creation}</td>
      <td className={styles.ContainedIn}>
        <div className={styles.UsedIn}>
          {element.containedIn?.length > 0 ? (
            <>
              {`${element.containedIn.length} ${t('nameAttributes.tables.diagrams')}`}
              <button className={styles.ExpandButton} onClick={handleExpandButtonClick} type="button">
                {isContainedInExpanded ? t('hide') : t('show')}
              </button>
            </>
          ) : (
            ''
          )}
        </div>
        {isContainedInExpanded && (
          <div className={styles.DiagramList}>
            {(element as InputOutputCatalog).containedIn?.map((diagram) => (
              <a
                className={styles.Link}
                href={diagram.status === STATUS.SANDBOX ? `/sandbox/${diagram.id}` : `/diagram/${diagram.id}`}
                onClick={(event) => event.stopPropagation()}
                rel="noopener noreferrer"
                target="_blank"
              >
                <>
                  <i className="di icon-link" />
                  {diagram.nameMap}
                </>
              </a>
            ))}
          </div>
        )}
      </td>
    </tr>
  );
};

export default InputOutputRow;
