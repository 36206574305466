import { DiagramProvider } from 'contexts/Diagram/DiagramContext';

import MyTasks from './MyTasks';

const MyTasksWrapper = () => {
  return (
    <DiagramProvider>
      <MyTasks />
    </DiagramProvider>
  );
};

export default MyTasksWrapper;
