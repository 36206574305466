import { DatasetProvider } from 'contexts/Dataset/DatasetContext';
import { DiagramProvider } from 'contexts/Diagram/DiagramContext';
import { RequirementProvider } from 'contexts/Requirement/RequirementContext';

import DatasetList from './DatasetList';

const DatasetListWrapper = () => {
  return (
    <DiagramProvider>
      <RequirementProvider>
        <DatasetProvider>
          <DatasetList />
        </DatasetProvider>
      </RequirementProvider>
    </DiagramProvider>
  );
};

export default DatasetListWrapper;
