import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { MODELING_STATUS, PUBLISHED_STATUS, REPORT_PROCESSES } from 'assets/constants/constants';
import { downloadBlob } from 'assets/js/Utils';
import Page from 'components/Page/Page';
import TitleBar from 'components/TitleBar/TitleBar';
import AdminCard from 'components/UI/AdminCard/AdminCard';
import useFeatureFlags from 'hooks/useFeatureFlags';
import documentationService from 'services/documentationService';
import titleService from 'services/titleService';

import styles from './Administration.module.scss';

// TODO: enum in tsx
const DownloadId = {
  [PUBLISHED_STATUS]: 'excel-published',
  [MODELING_STATUS]: 'excel-modeling',
  [REPORT_PROCESSES]: 'excel-processes',
};

const Administration = () => {
  const [loadingButtons, setLoadingButtons] = useState([]);
  const { t } = useTranslation();
  const { isAmountOfProcessesExcelEnabled, NEPOS } = useFeatureFlags();

  const addLoadingButton = (buttonId) => setLoadingButtons([...loadingButtons, buttonId]);

  const deleteLoadingButton = (buttonId) => {
    const newButtons = loadingButtons.filter(({ id }) => id === buttonId);
    setLoadingButtons(newButtons);
  };

  const downloadReport = (type) => {
    addLoadingButton(DownloadId[type]);
    documentationService
      .getReport(type)
      .then((res) => downloadBlob(res.data, res.headers, 'report.xlsx'))
      .catch(documentationService.handleServiceError)
      .finally(() => deleteLoadingButton(DownloadId[type]));
  };

  const downloadProcessesReport = () => {
    addLoadingButton(DownloadId[REPORT_PROCESSES]);
    documentationService
      .getProcessesReport()
      .then((res) => downloadBlob(res.data, res.headers, 'report.xlsx'))
      .catch(documentationService.handleServiceError)
      .finally(() => deleteLoadingButton(DownloadId[REPORT_PROCESSES]));
  };

  const adminCards = [
    {
      id: 'itSystems',
      title: t('itSystems'),
      icon: 'di icon-computer-laptop',
      url: '/it-systems',
    },
    {
      id: 'roles',
      title: t('roles'),
      icon: 'di icon-person',
      url: '/roles',
    },
    {
      id: 'openWorkflows',
      title: t('workflows.openWorkflows'),
      icon: 'di icon-pfeil-aktualisieren',
      url: '/workflows',
      tabSelected: 'openWorkflows',
    },
    {
      id: 'closedWorkflows',
      title: t('workflows.closedWorkflows'),
      icon: 'di icon-check',
      url: '/workflows',
      tabSelected: 'closedWorkflows',
    },
    {
      id: 'recycleBin',
      title: t('recycleBin.name'),
      icon: 'di icon-muelleimer-loeschen',
      url: '/recycle-bin',
    },
  ];

  if (NEPOS) {
    adminCards.push(
      {
        id: 'inputOutput',
        title: `${t('diagram.objects.input')}/${t('diagram.objects.output')}`,
        icon: 'di icon-herunterladen',
        url: '/input-output',
      },
      {
        id: 'inputOutputSwimlanes',
        title: `${t('diagram.objects.input')}/${t('diagram.objects.output')} (${t('NEPOS_SWIMLANE')})`,
        icon: 'di icon-herunterladen',
        url: '/input-output-swimlane',
        isLevel3: true,
      },
      {
        id: 'itSystemsSwimlanes',
        title: t('IT_SYSTEM_SWIMLANE'),
        icon: 'di icon-computer-laptop',
        url: '/it-systems-swimlane',
      },
      {
        id: 'rolesSwimlanes',
        title: `${t('roles')} (${t('NEPOS_SWIMLANE')})`,
        icon: 'di icon-person',
        url: '/roles-swimlane',
      },
    );
  }

  const downloadCards = [
    {
      id: DownloadId[PUBLISHED_STATUS],
      title: t('administration.downloads.excel-published'),
      icon: 'di icon-datei-herunterladen',
      isLoading: loadingButtons.includes(DownloadId[PUBLISHED_STATUS]),
      isButton: true,
      disabled: false,
      handleClick: () => downloadReport(PUBLISHED_STATUS),
    },
    {
      id: DownloadId[MODELING_STATUS],
      title: t('administration.downloads.excel-modeling'),
      icon: 'di icon-datei-herunterladen',
      isLoading: loadingButtons.includes(DownloadId[MODELING_STATUS]),
      disabled: false,
      isButton: true,
      handleClick: () => downloadReport(MODELING_STATUS),
    },
    {
      id: DownloadId[REPORT_PROCESSES],
      title: t('administration.downloads.excel-processes'),
      icon: 'di icon-datei-herunterladen',
      isLoading: loadingButtons.includes(DownloadId[REPORT_PROCESSES]),
      isButton: true,
      disabled: false,
      handleClick: () => downloadProcessesReport(),
      hidden: !isAmountOfProcessesExcelEnabled,
    },
  ];

  useEffect(() => {
    titleService.updatePageTitle(t('administration.name'));
  }, [t]);

  const prepareAdminCards = (cards) =>
    cards.filter((e) => !e.hidden).map((elem) => <AdminCard card={elem} id={elem.id} key={elem.title} />);

  return (
    <Page>
      <div className={styles.Content}>
        <div className={styles.Administration}>
          <h4>{t('administration.name')}</h4>
          <div className={styles.CardContent}>{prepareAdminCards(adminCards)}</div>
        </div>
        <div className={styles.Downloads}>
          <h4>{t('administration.downloads.name')}</h4>
          <div className={styles.CardContent}>{prepareAdminCards(downloadCards)}</div>
        </div>
      </div>
    </Page>
  );
};

export default Administration;
