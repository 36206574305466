import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { NOT_TRANSLATABLE } from 'assets/constants/constants';
import { getFormattedDate } from 'assets/js/Utils';
import CatalogObjectTable from 'components/CatalogObjectTable/CatalogObjectTable';
import Page from 'components/Page/Page';
import TitleBar from 'components/TitleBar/TitleBar';
import usePagination from 'hooks/usePagination';
import { findAllByObjectType } from 'services/design';
import titleService from 'services/titleService';
import { InputOutputCatalog, ObjectTypes } from 'types/administration';
import { Language } from 'types/config';
import { LinkedDiagram } from 'types/diagram';
import { TableTool } from 'types/tables';
import { CatalogUser } from 'types/user';

import InputOutputRow from './InputOutputRow';
import InputOutputSwimlaneRow from './InputOutputSwimlaneRow';

const InputOutput = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation<{ isLevel3: boolean }>();
  const isLevel3 = location.state?.isLevel3 || false;
  const inputOutputLabel = `${t('diagram.objects.input')}/${t('diagram.objects.output')}${
    isLevel3 ? ` (${t('NEPOS_SWIMLANE')})` : ''
  }`;
  const [catalogUsers, setCatalogUsers] = useState({});

  useEffect(() => {
    titleService.updatePageTitle(inputOutputLabel);
  }, [t, inputOutputLabel]);

  const tableHeader = {
    name: t('OBJECT_NAME'),
    description: t('OBJECT_DESCRIPTION'),
    ...(!isLevel3 && { responsiblePerson: t('responsiblePerson') }),
    ...(isLevel3 && { creator: t('creator') }),
    department: t('department'),
    status: t('STATUS'),
    ...(!isLevel3 && { qiGroup: t('qiGroup') }),
    creation: t('nameAttributes.tables.CREATED'),
    containedIn: t('containedIn'),
  };

  const getResults = useCallback(
    (params) =>
      findAllByObjectType(isLevel3 ? ObjectTypes.IO_SWIMLANE : ObjectTypes.IO_SIPOC, params).then((res) => {
        const results = res.data.results.map((elem: InputOutputCatalog) => {
          return {
            id: elem.id,
            name: elem.attributes?.[i18n.language.toUpperCase() as Language]?.NAME,
            description: elem.attributes?.[i18n.language.toUpperCase() as Language]?.DESCRIPTION,
            ...(!isLevel3 && {
              responsiblePerson: res.data.catalog.USER.find(
                (catalogUser: CatalogUser) => catalogUser.code === elem.attributes?.[NOT_TRANSLATABLE]?.RESPONSIBLE_PERSON,
              )?.commonName,
              qiGroup: elem.attributes?.[NOT_TRANSLATABLE]?.RESPONSIBLE_AREA,
            }),
            ...(isLevel3 && {
              creator: res.data.catalog.USER.find(
                (catalogUser: CatalogUser) => catalogUser.code === elem.attributes?.[NOT_TRANSLATABLE]?.CREATOR,
              )?.commonName,
            }),
            department: res.data.catalog.USER.find(
              (catalogUser: CatalogUser) => catalogUser.code === elem.attributes?.[NOT_TRANSLATABLE]?.CREATOR,
            )?.department,
            containedIn: elem.diagramIdentifierList?.map((diagram: LinkedDiagram) => {
              return {
                id: diagram.id,
                nameMap: diagram.nameMap[i18n.language as Language]?.PROCESS_NAME,
                status: diagram.status,
              };
            }),
            creation: getFormattedDate(elem.attributes?.[NOT_TRANSLATABLE]?.CREATION),
            status: t(elem.status),
            attributes: elem.attributes,
          };
        });

        setCatalogUsers(res.data?.catalog);

        return {
          numberOfPages: res.data.numberOfPages,
          results,
          totalResults: res.data.totalResults,
        };
      }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const pagination = usePagination({ getResults, pageSize: 10 });

  return (
    <Page>
      <CatalogObjectTable
        catalogUsers={catalogUsers}
        header={tableHeader}
        isLegacy={false}
        labelX={inputOutputLabel}
        objectType={isLevel3 ? ObjectTypes.IO_SWIMLANE : ObjectTypes.IO_SIPOC}
        pagination={pagination}
        rowComponent={isLevel3 ? InputOutputSwimlaneRow : InputOutputRow}
        tools={[TableTool.NEW, TableTool.EDIT, TableTool.DELETE]}
      />
    </Page>
  );
};

export default InputOutput;
