import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import * as Constants from 'assets/constants/constants';
import * as Utils from 'assets/js/Utils';
import DialogHistory from 'components/DialogHistory/DialogHistory';
import TitleBar from 'components/TitleBar/TitleBar';
import DialogWarning from 'components/UI/DialogBasics/DialogWarning';
import RecommendationsRow from 'components/UI/TableList/RecommendationsRow/RecommendationsRow';
import TableList from 'components/UI/TableList/TableList';
import communicationService from 'services/communicationService';
import documentationService from 'services/documentationService';
import recommendationService from 'services/recommendationService';
import titleService from 'services/titleService';

import styles from './ProcessRecommendations.module.scss';

const ProcessRecommendations = (props) => {
  const { t, i18n } = useTranslation();
  const header = {
    name: t('tasks.name'),
    locations: t('recommendations.table.locations'),
    recommendedOn: t('recommendations.table.recommendedOn'),
    recommendedBy: t('recommendations.table.recommendedBy'),
    status: t('recommendations.table.status'),
    feedback: t('recommendations.table.feedback'),
    gaplog: t('recommendations.table.gaplog'),
    report: t('recommendations.table.report'),
    history: t('history.title'),
    update: t('recommendations.table.update'),
  };

  const [processRecommendations, setProcessRecommendations] = useState(null);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [dataHistory, setDataHistory] = useState(null);
  const [locations, setLocations] = useState([]);
  const [isEmptyDialogVisible, setIsEmptyDialogVisible] = useState(false);

  const processNumber = props.location.state?.processNumber;
  const language = i18n.language.toUpperCase();

  const getUser = (user) => {
    let lastUser = '';

    if (user) {
      lastUser = !user.departments
        ? user.commonName
        : user.departments.reduce((acc, department, i, vector) => {
            if (i === 0 && vector.length === 1) return `${acc}, (${department})`;
            if (i === 0) return `${acc}, (${department}, `;
            if (i === vector.length - 1) return `${acc}${department})`;

            return `${acc}${department}, `;
          }, user.commonName);
    }

    return lastUser;
  };

  const mapRecommendations = (recommendationsData) => {
    const processRecomensations = recommendationsData.map((elem) => ({
      name: JSON.parse(elem.name)[language],
      processNumber: elem.processNumber,
      locations: elem.locations.map((location) => {
        const lastUser = location.recommendedBy ? getUser(location.recommendedBy) : '-';
        return {
          id: location.id,
          locationCode: location.location,
          location: locations.find((loc) => loc.code === location.location).displayName,
          isPublished: location.status === Constants.PUBLISHED_STATUS,
          recommendedOn: Utils.getFormattedDate(location.recommendedOn),
          recommendedBy: lastUser,
          status: t(`recommendations.status.${location.status}`),
          feedback: t(`recommendations.feedback.${location.feedback}`),
          gaplog: location.gapLogCategory
            ? t(`attributes.${Constants.GAP_LOG_CATEGORY}.options.${location.gapLogCategory}`)
            : '-',
          update: false,
          version: location.versionPlant || 'last',
        };
      }),
    }));
    setProcessRecommendations(processRecomensations);
  };

  const getProcessRecommendations = (params) => {
    recommendationService
      .getProcessRecommendations({ ...params, processNumber })
      .then((res) => {
        if (res.data.name === null) {
          setIsEmptyDialogVisible(true);
          mapRecommendations([]);
        } else {
          mapRecommendations([res.data]);
        }
      })
      .catch((err) => recommendationService.handleServiceError(err));
  };

  const getPlants = () => {
    communicationService
      .getLocations()
      .then((response) => {
        const activeLocations = response.data.map((elem) => ({
          code: elem.code,
          displayName: `${elem.name} - ${elem.location}, ${elem.country}${elem.daimlerCode ? ` (${elem.daimlerCode})` : ''}`,
        }));
        setLocations(activeLocations);
      })
      .catch((err) => {
        communicationService.handleServiceError(err);
      });
  };

  useEffect(() => {
    if (locations.length) {
      setProcessRecommendations(null);
      getProcessRecommendations();
    }
  }, [locations.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    titleService.updatePageTitle(t('recommendations.processRecommendations'));
    getPlants();
  }, [t]);

  const openHistoryDialog = (recommendation) => {
    setShowHistoryDialog(true);

    recommendationService
      .getRecommendationVersions(recommendation.locationCode, recommendation.processNumber)
      .then((res) => {
        let historyList = [];

        historyList = res.data.map((elem) => ({
          name: JSON.parse(elem.processName)[language].TITLE,
          processNumber: elem.processNumber,
          date: Utils.getFormattedDate(elem.date),
          recommendedBy: getUser(elem.recommendedBy) || '-',
          status: t(`recommendations.status.${elem.status}`),
          gaplog: elem.gapLog ? t(`attributes.${Constants.GAP_LOG_CATEGORY}.options.${elem.gapLog}`) : '-',
          version: elem.version,
          versionPlant: elem.versionPlant,
          locationCode: recommendation.locationCode,
        }));

        setDataHistory(historyList);
      })
      .catch((err) => {
        recommendationService.handleServiceError(err);
      });
  };

  const switchUpdate = () => {
    // TODO
  };

  const handleDownloadClick = (element) => {
    documentationService
      .getDocument(element.processNumber, element.version, language, Constants.EXCHANGE, 'pdf', element.locationCode)
      .then((res) => {
        const fileName = `${element.name}.pdf`;
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        Utils.downloadFile(url, fileName, res.headers['content-disposition']);
      })
      .catch((err) => {
        documentationService.handleServiceError(err);
      });
  };

  return (
    <>
      <TitleBar />
      <div className={styles.Content}>
        <div className={styles.TableContent}>
          <TableList
            handleDownloadClick={handleDownloadClick}
            header={header}
            list={processRecommendations}
            recommendationsTable
            RowComponent={RecommendationsRow}
            showHistoryDialog={openHistoryDialog}
            switchUpdate={switchUpdate}
          />
        </div>
      </div>
      <div id="dialog">
        {showHistoryDialog && (
          <DialogHistory
            close={() => {
              setShowHistoryDialog(false);
              setDataHistory(null);
            }}
            dataHistory={dataHistory}
            handleDownloadClick={handleDownloadClick}
            isRecommendationHistory
            language={language}
            processNumber={processNumber}
            refAssignment={() => {}}
          />
        )}
        {isEmptyDialogVisible && (
          <DialogWarning handleConfirm={() => setIsEmptyDialogVisible(false)}>
            {t('recommendations.isEmptyMessage')}
          </DialogWarning>
        )}
      </div>
    </>
  );
};

export default ProcessRecommendations;
