import { useRef, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Toolbar from 'components/Toolbar/Toolbar';
import TableList from 'components/UI/TableList/TableList';

import * as Constants from '../../assets/constants/constants';
import objectCatalogService from '../../services/objectCatalogService';
import Button from '../UI/Button/Button';
import * as Dialog from '../UI/Dialogs/Dialogs';
import styles from './MergeCatalogObjects.module.scss';

const MergeCatalogObjects = (props) => {
  const { header, hideMergeView, objectType, RowComponent, selectedObjects, setLoadingMerge } = props;
  const { t, i18n } = useTranslation();
  const [selectedObjectId, setSelectedObjectId] = useState(selectedObjects[0]?.id);
  const mergeDialog = useRef(null);
  const objectsTypeOptions = {
    [Constants.ROLE]: t('roles'),
    [Constants.IT_SYSTEM]: t('itSystems'),
    [Constants.IT_SYSTEM_SWIMLANE]: `${t('itSystems')} (${t('NEPOS_SWIMLANE')})`,
    [Constants.ROLE_SWIMLANE]: `${t('roles')} (${t('NEPOS_SWIMLANE')})`,
  };
  const objectsType = objectsTypeOptions[objectType];
  const singleObjectTypeOptions = {
    [Constants.ROLE]: t('leadingRole'),
    [Constants.IT_SYSTEM]: t('leadingItSystem'),
    [Constants.IT_SYSTEM_SWIMLANE]: `${t('leadingItSystem')} (${t('NEPOS_SWIMLANE')})`,
    [Constants.ROLE_SWIMLANE]: `${t('leadingRole')} (${t('NEPOS_SWIMLANE')})`,
  };
  const singleObjectType = singleObjectTypeOptions[objectType];
  const mergeCatalogObjects = (objectIdListToMerge) => {
    const idObjectType = {
      [Constants.ROLE]: Constants.ROLE_ID,
      [Constants.IT_SYSTEM]: Constants.IT_SYSTEM_ID,
      [Constants.IT_SYSTEM_SWIMLANE]: Constants.IT_SYSTEM_SWIMLANE_ID,
      [Constants.ROLE_SWIMLANE]: Constants.ROLE_SWIMLANE_ID,
    };
    const data = {
      idObjectType: idObjectType[objectType],
      leadingObjectId: selectedObjectId,
      objectIdListToMerge,
    };

    setLoadingMerge(true);
    objectCatalogService
      .mergeObjectsCatalog(data)
      .then(() => {
        setLoadingMerge(false);
        hideMergeView();
      })
      .catch((err) => {
        objectCatalogService.handleServiceError(err);
        setLoadingMerge(false);
        hideMergeView();
      });
  };

  const showMergeDialog = () => {
    const objectCatalogTypeOptions = {
      [Constants.ROLE]: t('diagram.objects.role'),
      [Constants.IT_SYSTEM]: t('diagram.objects.itSystem'),
      [Constants.IT_SYSTEM_SWIMLANE]: t('IT_SYSTEM_SWIMLANE'),
      [Constants.ROLE_SWIMLANE]: t('ROLE_SWIMLANE'),
    };
    const objectCatalogType = objectCatalogTypeOptions[objectType];
    const objectSelection = selectedObjects.find((object) => object.id === selectedObjectId);
    const selectedObjectName = `<div class='objects-merge'>${
      objectSelection.name || objectSelection.attributes[i18n.language].OBJECT_NAME
    }</div>`;
    const filteredObjects = selectedObjects.filter((object) => object.id !== selectedObjectId);
    const objectsNames = filteredObjects
      .map((object) => `<span class="object-name">${object.name || object.attributes[i18n.language].OBJECT_NAME}</span>`)
      .join('<br/>');
    const filteredObjectsIds = filteredObjects.map((object) => object.id);
    const message = `
      ${t(`mergeDialog.warning_${objectType}`)}
      <div class='merge-content'>
        ${t(`mergeDialog.content_${objectType}`, { objectName: selectedObjectName })}
        <div class='objects-merge'>
          ${objectsNames}
        </div>
      </div>
    `;
    const mergeDialogData = {
      title: t('mergeDialog.title', { objectCatalogType }),
      message,
      textCancel: t('cancel'),
      textOk: t('tool.merge'),
      okClick: () => {
        mergeDialog.current.hide();
        mergeCatalogObjects(filteredObjectsIds);
      },
    };

    mergeDialog.current = Dialog.showConfirm(mergeDialogData);
  };

  const mergeToolbarButtons = [
    {
      handleClick: hideMergeView,
      buttonStyle: 'Secondary',
      btnText: t('cancel'),
      id: 'cancel',
      component: Button,
    },
    {
      handleClick: showMergeDialog,
      buttonStyle: 'Primary',
      btnText: t('tool.merge'),
      id: 'merge',
      component: Button,
    },
  ];

  const list = useMemo(
    () => selectedObjects.map((obj) => ({ ...obj, checked: obj.id === selectedObjectId })),
    [selectedObjects, selectedObjectId],
  );

  return (
    <>
      <Toolbar
        buttons={mergeToolbarButtons}
        isWorkflow
        objectsType={objectsType}
        onlyRead
        onlyReadReason="selectLead"
        singleObjectType={singleObjectType}
      />
      <div className={styles.Content}>
        <div className={styles.Title}>
          <h4>{t('catalog.mergeTitle', { objectsType })}</h4>
        </div>
        <TableList
          checkedItems={[{ id: selectedObjectId }]}
          handleRowClick={setSelectedObjectId}
          header={header}
          isRadio
          list={list}
          RowComponent={RowComponent}
        />
      </div>
    </>
  );
};

export default MergeCatalogObjects;
