import { DiagramProvider } from 'contexts/Diagram/DiagramContext';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useLocalization from 'hooks/useLocalization';

import Dashboard from './Dashboard';

const DashboardWrapper = (props) => {
  const { availableLanguages } = useLocalization();
  const { isFreezed } = useFeatureFlags();
  return (
    <DiagramProvider>
      <Dashboard {...props} availableLanguages={availableLanguages} isFreezed={isFreezed} />
    </DiagramProvider>
  );
};

export default DashboardWrapper;
