import { DiagramProvider } from 'contexts/Diagram/DiagramContext';

import InputOutput from './InputOutput';

const InputOutputWrapper = () => {
  return (
    <DiagramProvider>
      <InputOutput />
    </DiagramProvider>
  );
};

export default InputOutputWrapper;
