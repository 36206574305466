import React, { useMemo, useRef } from 'react';

import { Link, useLocation } from 'react-router-dom';

import styles from './LandscapeProcess.module.scss';

const LandscapeProcess = ({ diagramCenter, id, isManagementProcess = false, processName, images, e2e }) => {
  const location = useLocation();
  const processRef = useRef(null);
  const processClass = isManagementProcess ? styles.ManagementProcess : '';

  const skewAngle = useMemo(() => {
    if (!diagramCenter || !processRef?.current) return 0;

    const processBounds = processRef.current?.getBoundingClientRect();
    const processCoords = {
      x: isManagementProcess ? processBounds.right : processBounds.left,
      y: processBounds.top,
    };

    return Math.atan2(diagramCenter.y - processCoords.y, diagramCenter.x - processCoords.x);
  }, [diagramCenter, isManagementProcess]);

  const LinkWrapper = ({ children }) => {
    const isE2e = e2e ? `/${e2e}` : '';
    if (typeof id === 'number') {
      return (
        <Link className={styles.Link} to={`/${location.pathname.includes('published') ? 'published' : 'diagram'}${isE2e}/${id}`}>
          {children}
        </Link>
      );
    }

    return (
      <a className={styles.Link} href={id} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  };

  const ImagesWrapper = ({ children }) => (images.length > 1 ? <div className={styles.Images}>{children}</div> : <>{children}</>);

  return (
    <div className={`${styles.Process} ${processClass}`} ref={processRef}>
      <div className={styles.Shadow} style={{ transform: `skewY(${skewAngle}rad)` }} />
      <div className={styles.ImageWrapper}>
        <ImagesWrapper>
          {images.map(({ code, url, userName }) => (
            <div className={styles.Image} key={code}>
              <a href={`https://social.intra.corpintra.net/people/${code}`} rel="noopener noreferrer" target="_blank">
                <img alt={userName} src={url} />
              </a>
            </div>
          ))}
        </ImagesWrapper>
      </div>
      <LinkWrapper>
        <span className={styles.ProcessName}>{processName}</span>
      </LinkWrapper>
    </div>
  );
};

export default LandscapeProcess;
