import { useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { EPC_DIAGRAM_ID } from 'assets/constants/constants';
import VersionsDropDown from 'components/VersionsDropDown/VersionsDropDown';
import { DataSet, DataSetHeader } from 'types/dataset';
import { Coordinates } from 'types/diagram';

import styles from './DatasetRow.module.scss';

type Props = {
  direction: string;
  element: DataSet | DataSetHeader;
  fieldToOrder: string;
  isHeader: boolean;
  sortTable: (filter: string) => void;
  handleDownloadClick: (name: string, id: number, version?: string) => void;
  handleRowClick: (id: number) => void;
};

const DatasetRow = (props: Props) => {
  const { direction, element, fieldToOrder, isHeader, sortTable, handleDownloadClick, handleRowClick } = props;
  const documentRef = useRef<HTMLDivElement>(null);
  const versionRef = useRef<HTMLDivElement>(null);
  const documentDropdownRef = useRef<HTMLDivElement>(null);
  const versionDropdownRef = useRef<HTMLDivElement>(null);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showVersions, setShowVersions] = useState(false);
  const [coords, setCoords] = useState<Coordinates>({ left: 0, top: 0 });
  const history = useHistory();

  useEffect(() => {
    if (!documentRef.current && !versionRef.current && !showDocuments && !showVersions) return;

    const ref = showDocuments ? documentRef.current : versionRef.current;
    const dropdownRef = showDocuments ? documentDropdownRef.current : versionDropdownRef.current;
    const processBounds = ref?.getBoundingClientRect();

    if (!processBounds || !dropdownRef) return;

    const offsetX = 128;
    let offsetY = processBounds.bottom;

    if (processBounds.bottom + dropdownRef.offsetHeight > window.innerHeight) {
      offsetY = processBounds.top - dropdownRef.offsetHeight;
    }

    setCoords({
      left: processBounds.left - offsetX,
      top: offsetY,
    });
  }, [showDocuments, showVersions]);

  const getSortedClass = (field: string) => (fieldToOrder === field ? styles[direction] : '');

  const handleOpenDocuments = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if ((element as DataSet).documents?.length > 0) setShowDocuments(!showDocuments);
  };

  const handleDownloadDocument = (e: React.MouseEvent, name: string, id: number, version: string) => {
    e.preventDefault();
    e.stopPropagation();
    handleDownloadClick(name, id, version);
  };

  const handleNavigateDiagram = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/diagram/${(element as DataSet).link.id}`);
  };

  if (isHeader) {
    const item = element as DataSetHeader;

    return (
      <tr className={`${styles.Header} ${styles.ListItem}`}>
        <th className={`${styles.Name} ${getSortedClass('name')}`} onClick={() => sortTable('name')}>
          {item.name}
        </th>
        <th className={`${styles.Description} ${getSortedClass('description')}`} onClick={() => sortTable('description')}>
          {item.description}
        </th>
        <th className={`${styles.VersionColumn} ${getSortedClass('version')}`} onClick={() => sortTable('version')}>
          {item.versions}
        </th>
        <th className={`${styles.Created} ${getSortedClass('creationDate')}`} onClick={() => sortTable('creationDate')}>
          {item.created}
        </th>
        <th className={`${styles.Link} ${styles.NonSortable}`}>{item.link}</th>
        <th className={`${styles.Documents} ${styles.NonSortable}`}>{item.documents}</th>
      </tr>
    );
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  const row = element as DataSet;

  return (
    <tr className={styles.ListItem} key={row.id} onClick={() => handleRowClick(row.id)}>
      <td className={styles.Name}>
        <span className={styles.DatasetId}>{row.id}</span>
        {row.name}
      </td>
      <td className={styles.Description}>{row.description}</td>
      <td className={styles.VersionColumn}>
        {row.versions && (
          <VersionsDropDown
            coords={coords}
            element={row}
            setShowVersions={setShowVersions}
            showVersions={showVersions}
            versionDropdownRef={versionDropdownRef}
            versionRef={versionRef}
          />
        )}
      </td>
      <td className={styles.Created}>{row.created}</td>
      <td className={styles.Link}>
        {row.link && row.link.id ? (
          <>
            <i className={`di ${EPC_DIAGRAM_ID ? 'icon-hierarchie-diagramm' : 'icon-etikett-2'}`} />
            <span className={styles.LinkName} onClick={handleNavigateDiagram}>
              {row.link.name}
            </span>
          </>
        ) : (
          ''
        )}
      </td>
      <td className={styles.Documents}>
        <div className={styles.DocumentsButton} onClick={handleOpenDocuments} onMouseLeave={() => setShowDocuments(false)}>
          <div className={styles.Icon} ref={documentRef}>
            <i className="di icon-bueroklammer-anhang" />
            <span className={styles.DocumentsNumber}>{row.documents?.length | 0}</span>
          </div>
          {showDocuments && (
            <div className={styles.DocumentsDropDown} ref={documentDropdownRef} style={coords}>
              <div className={styles.DocumentsWrapper}>
                {row.documents.map((doc) => {
                  return (
                    <div
                      className={styles.Document}
                      key={`document-${row.id}`}
                      onClick={(e) => handleDownloadDocument(e, doc.name, row.id, row.versions[0].version)}
                    >
                      <span className={styles.Name}>{doc.name}</span>
                      <div className={styles.Details}>
                        <span>{doc.creator}</span>
                        <span>{doc.date}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default DatasetRow;
