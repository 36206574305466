import { DatasetProvider } from 'contexts/Dataset/DatasetContext';
import { DiagramProvider } from 'contexts/Diagram/DiagramContext';
import { ProcessLevelProvider } from 'contexts/ProcessLevel/ProcessLevelContext';
import { RequirementProvider } from 'contexts/Requirement/RequirementContext';

import RequirementList from './RequirementList';

const RequirementListWrapper = () => {
  return (
    <DiagramProvider>
      <ProcessLevelProvider>
        <RequirementProvider>
          <DatasetProvider>
            <RequirementList />
          </DatasetProvider>
        </RequirementProvider>
      </ProcessLevelProvider>
    </DiagramProvider>
  );
};

export default RequirementListWrapper;
