import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { NOT_TRANSLATABLE } from 'assets/constants/constants';
import { handleServiceError } from 'assets/js/serviceUtils';
import { downloadBlob, getFormattedDate, getFullFormattedDate } from 'assets/js/Utils';
import Page from 'components/Page/Page';
import TitleBar from 'components/TitleBar/TitleBar';
import Toolbar from 'components/Toolbar/Toolbar';
import Button from 'components/UI/Button/Button';
import Pagination from 'components/UI/Pagination/Pagination';
import Search from 'components/UI/Search/Search';
import Spinner from 'components/UI/Spinner/Spinner';
import DatasetRow from 'components/UI/TableList/DatasetRow/DatasetRow';
import TableList from 'components/UI/TableList/TableList';
import TabSelector from 'components/UI/TabSelector/TabSelector';
import useDataset from 'hooks/useDataset';
import useDiagramContext from 'hooks/useDiagramContext';
import useFeatureFlags from 'hooks/useFeatureFlags';
import usePagination from 'hooks/usePagination';
import { getAllDatasets } from 'services/dataset';
import { getAllPublishedDatasets } from 'services/documentation';
import { getDatasetDocument } from 'services/documentationService';
import { DatasetData } from 'types/dataset';

import styles from './DatasetList.module.scss';

const DatasetList = () => {
  enum DatasetTabIds {
    PUBLISHED = 'published-datasets-button',
    MODELING = 'modeling-datasets-button',
  }
  const { t, i18n } = useTranslation();
  const { isLoading: isGlobalLoading } = useDiagramContext();
  const history = useHistory();
  const { createNewDataset } = useDataset();
  const [tabSelected, setTabSelected] = useState(DatasetTabIds.PUBLISHED);
  const { isFreezed } = useFeatureFlags();

  const getResults = useCallback(
    (params) => {
      const service =
        tabSelected === DatasetTabIds.PUBLISHED ? getAllPublishedDatasets(params, i18n.language) : getAllDatasets(params);
      return service.then((res) => {
        const { catalog } = res.data;

        const results = res.data.datasets.map((elem: DatasetData) => {
          return {
            id: elem.id,
            name: elem.attributes[i18n.language]?.NAME,
            description: elem.attributes[i18n.language]?.DESCRIPTION,
            versions:
              !elem?.versions || elem.versions.length === 0
                ? [{ version: '0.0' }]
                : elem?.versions.map((element) => {
                    return {
                      user: element.author.commonName,
                      version: element.version,
                      date: getFullFormattedDate(element.validFrom),
                    };
                  }),
            created: getFormattedDate(elem.attributes[NOT_TRANSLATABLE]?.CREATION_DATE),
            link: {
              id: elem.linkedDiagram?.id,
              name: elem.linkedDiagram?.attributes[i18n.language]?.PROCESS_NAME,
              type: elem.linkedDiagram?.type,
            },
            documents: elem.documents?.map((el) => {
              return {
                ...el,
                date: getFullFormattedDate(el.date),
                creator: catalog?.USER.find(
                  (user: {
                    code: string;
                    commonName?: string;
                    departments?: string[];
                    department?: string;
                    linkTeams?: string;
                  }) => user.code === el.creator,
                )?.commonName,
              };
            }),
          };
        });

        return {
          numberOfPages: res.data.numberOfPages,
          results,
          totalResults: res.data.totalDataSet,
        };
      });
    },
    [tabSelected], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const header = {
    name: t('nameAttributes.PROCESS_NAME'),
    description: t('nameAttributes.PROCESS_DESCRIPTION'),
    versions: t('nameAttributes.VERSION'),
    created: t('nameAttributes.tables.CREATED'),
    link: t('nameAttributes.LINKED_EPC_VCD'),
    documents: t('nameAttributes.tables.documents'),
  };

  const handleDowloadDocument = (name: string, id: number, version: string) => {
    getDatasetDocument({ id, name, version: tabSelected === DatasetTabIds.PUBLISHED ? version : undefined })
      .then((res) => {
        downloadBlob(res.data, res.headers, name);
      })
      .catch((error) => {
        handleServiceError(error);
      });
  };

  const handleRowClick = (id: number) => {
    const envToRedirect = tabSelected === DatasetTabIds.PUBLISHED ? '-published' : '';
    history.push(`/dataset${envToRedirect}/${id}`);
  };

  const { isLoading, results, sorting, sortTable, totalPages, page, setPage, handleSearchChange } = usePagination({
    getResults,
    pageSize: 10,
  });

  const datasetLists: { id: string; name: string }[] = [
    {
      id: DatasetTabIds.PUBLISHED,
      name: t('publishedArea'),
    },
    {
      id: DatasetTabIds.MODELING,
      name: t('modelingArea'),
    },
  ];

  return (
    <>
      <div className={isGlobalLoading ? styles.Spinner : ''}>
        <Spinner isVisible={isGlobalLoading} />
      </div>
      <Page>
        <Toolbar
          buttons={[
            {
              component: Button,
              buttonStyle: 'Primary',
              btnText: t('dataset.button.create'),
              disabled: isFreezed || isGlobalLoading,
              handleClick: () => {
                createNewDataset();
              },
              id: 'transfer',
            },
          ]}
          leftButtons={
            <>
              <TabSelector
                className={styles.Tabs}
                handleClick={setTabSelected}
                isNEPOS
                options={datasetLists.map((option: { id: string; name: string }) => ({
                  id: option.id,
                  name: option.name,
                  isValid: true,
                }))}
                selectedOption={tabSelected}
              />
            </>
          }
          showToolbarButtons
        />
        <div className={styles.Content}>
          <h4>{t('documentManagementTitle')}</h4>
          <div className={styles.SearchWrapper}>
            <Search
              disabled={isLoading}
              extraClass={styles.Search}
              id="dataset-list-search"
              isAsync
              searching={handleSearchChange}
            />
          </div>
          <TableList
            blockBody
            direction={sorting.order}
            fieldToOrder={sorting.filter}
            handleDownloadClick={(name: string, id: number, version: string) => handleDowloadDocument(name, id, version)}
            handleRowClick={handleRowClick}
            header={header}
            isLoading={isLoading}
            list={results}
            RowComponent={DatasetRow}
            sortTable={sortTable}
          />
          {!isLoading && !!totalPages && totalPages > 1 && (
            <div className={styles.PagesSection}>
              <Pagination page={page} pageClick={setPage} totalPages={totalPages} />
            </div>
          )}
        </div>
      </Page>
    </>
  );
};

export default DatasetList;
