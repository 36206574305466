import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { BUTTON_PRIMARY, BUTTON_SECONDARY, CREATE, EDIT, NOT_TRANSLATABLE, PL } from 'assets/constants/constants';
import DialogNEPOS from 'components/UI/DialogNEPOS/DialogNEPOS';
import TableWithSearch from 'components/UI/TableWithSearch/TableWithSearch';
import useBackendForm from 'hooks/useBackendForm';
import useFormTypes from 'hooks/useFormTypes';
import useProcessLevelDatabase from 'hooks/useProcessLevel';
import useProcessLevelDatabaseContext from 'hooks/useProcessLevelContext';
import titleService from 'services/titleService';
import { Language } from 'types/config';
import { AttributeCode, Field, LanguageAttributes } from 'types/forms';
import { Levels, ProcessLevelSelectionTableRow, ProcessLevelFormatted } from 'types/processLevel';
import { TableColumn, TableVariant } from 'types/tables';

import styles from './ProcessLevelFormDialog.module.scss';

interface Props {
  processes?: ProcessLevelFormatted[];
  defaultValues?: ProcessLevelFormatted; // TODO De momento, cambiar a mayor especifidad
  onClose: () => void;
  onConfirm: (values, linkedLevel) => void;
}

const ProcessLevelFormDialog = ({ processes = [], onConfirm, onClose, defaultValues }: Props) => {
  const { i18n, t } = useTranslation();
  const { formatProcessToAttributeValues, getAllProcessesByLevel } = useProcessLevelDatabase();
  const { catalog } = useProcessLevelDatabaseContext();
  const [showSelectProcessDialog, setShowSelectProcessDialog] = useState<boolean>(false);
  const [selectProcessLevelDialogRows, setSelectProcessLevelDialogRows] = useState<ProcessLevelSelectionTableRow[] | undefined>();
  const [isFirstLinkedLevelEnabled, setIsFirstLinkedLevelEnabled] = useState<boolean>(false);
  const [isSecondLinkedLevelEnabled, setIsSecondLinkedLevelEnabled] = useState<boolean>(false);
  const [selectedLinkedLevel, setSelectedLinkedLevel] = useState<string>('');
  const [linkedLevel, setLinkedLevel] = useState<number | undefined>(defaultValues?.parentId);
  const [linkProcessDialogSearchText, setLinkProcessDialogSearchText] = useState<string>('');
  const [filteredSelectProcessLevelDialogRows, setFilteredSelectProcessLevelDialogRows] = useState<
    ProcessLevelSelectionTableRow[] | undefined
  >();
  const { getFormTypesCode } = useFormTypes();

  const editFormCode = getFormTypesCode({
    type: PL,
    actionType: EDIT,
  });

  const createFormCode = getFormTypesCode({
    type: PL,
    actionType: CREATE,
  });

  const getParentOfSelectedItem = (): ProcessLevelFormatted | undefined => {
    if (!processes || !defaultValues) return;
    if (defaultValues.level === 2) return processes.find((process) => Number(process.id) === defaultValues.parentId);
    if (defaultValues.level === 3) {
      return processes
        .map((process) => process.children)
        .flat()
        .find((process) => Number(process.id) === defaultValues.parentId);
    }

    return undefined;
  };

  const { fields, purifyForm, setValue, values } = useBackendForm(
    defaultValues
      ? {
          formCode: editFormCode,
          initialValues: formatProcessToAttributeValues(defaultValues, getParentOfSelectedItem()),
          id: defaultValues?.id,
          catalog,
        }
      : { formCode: createFormCode, catalog },
  );

  const processLevelFields = fields[i18n.language as Language]?.slice(2, 4);

  const handleOnClickParentLevelDialog = (level: number): void => {
    getAllProcessesByLevel(level).then((res) =>
      setSelectProcessLevelDialogRows(res?.filter((process) => defaultValues?.id !== process.id)),
    );
    setShowSelectProcessDialog(true);
  };

  useEffect(() => {
    if (linkProcessDialogSearchText.length > 0)
      return setFilteredSelectProcessLevelDialogRows(
        selectProcessLevelDialogRows?.filter((process) =>
          process.processTitle.toLowerCase().includes(linkProcessDialogSearchText.toLowerCase()),
        ),
      );

    setFilteredSelectProcessLevelDialogRows(selectProcessLevelDialogRows);
  }, [linkProcessDialogSearchText, selectProcessLevelDialogRows]);

  const getProcessFromId = (id?: number): ProcessLevelFormatted | undefined =>
    id
      ? processes?.find(
          (process) => Number(process.id) === id || process.children.some((child) => Number(child.id) === Number(id)),
        )
      : undefined;

  const getProcessTitleFromId = (id?: number): string => {
    const processSelected = getProcessFromId(id);

    if (!processSelected) return '';

    return Number(processSelected.id) === id
      ? processSelected.title
      : processSelected.children.find((children) => Number(children.id) === id)?.title || '';
  };

  const hasCriticalChangesFromDefault = (currentValues: LanguageAttributes): boolean =>
    !!defaultValues &&
    currentValues.LEVEL === Object.values(Levels)[defaultValues.level - 1] &&
    currentValues.CATEGORY === defaultValues.category &&
    currentValues.COORDINATOR === defaultValues.coordinators &&
    (Levels.LEVEL_1 === currentValues.LEVEL ||
      (Levels.LEVEL_2 === currentValues.LEVEL &&
        currentValues.LEVEL1_PROCESS === getProcessTitleFromId(defaultValues.parentId)) ||
      (Levels.LEVEL_3 === currentValues.LEVEL && currentValues.LEVEL2_PROCESS === getProcessTitleFromId(defaultValues.parentId)));

  useEffect(() => {
    const currentValues = values[NOT_TRANSLATABLE];

    if (!currentValues) return;

    setIsFirstLinkedLevelEnabled(Levels.LEVEL_2 === currentValues.LEVEL);
    setIsSecondLinkedLevelEnabled(Levels.LEVEL_3 === currentValues.LEVEL);

    if (!hasCriticalChangesFromDefault(currentValues)) {
      setLinkedLevel(undefined);
      setValue(AttributeCode.LEVEL1_PROCESS, '');
      setValue(AttributeCode.LEVEL2_PROCESS, '');
      //  setValue(AttributeCode.CATEGORY);
      setValue(AttributeCode.COORDINATOR);
      purifyForm();
    }
  }, [values[NOT_TRANSLATABLE]?.LEVEL]); // eslint-disable-line react-hooks/exhaustive-deps

  titleService.updatePageTitle('Process level database');

  useEffect(() => {
    if (defaultValues?.parentId) {
      setLinkedLevel(defaultValues.parentId);
    }
  }, [defaultValues?.parentId]);

  useEffect(() => {
    const selectedProcessTitle = getProcessTitleFromId(linkedLevel);
    const selectedProcessLinked = getProcessFromId(linkedLevel);

    if (selectedProcessLinked) {
      setValue(AttributeCode.LEVEL1_PROCESS, isFirstLinkedLevelEnabled ? selectedProcessTitle : undefined);
      setValue(AttributeCode.LEVEL2_PROCESS, isSecondLinkedLevelEnabled ? selectedProcessTitle : undefined);
      setValue(AttributeCode.CATEGORY, selectedProcessLinked.category);
      setValue(AttributeCode.COORDINATOR, selectedProcessLinked.coordinators);
      purifyForm();
    }
  }, [linkedLevel]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectProcessLevelDialogColumns: TableColumn<ProcessLevelSelectionTableRow>[] = [
    { id: 'processTitle', style: styles.ProcessTitle },
    { id: 'processLevel', style: styles.ProcessLevel },
    { id: 'processResponsibles', style: styles.Person },
    { id: 'department', style: styles.Department },
  ];

  const dialogButtonsCreateEditDialog = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: !defaultValues ? t('create') : t('confirm'),
      id: `processLevelDatabase-${defaultValues ? EDIT.toLowerCase() : CREATE.toLowerCase()}-submit`,
      handleClick: () => onConfirm(values, linkedLevel),
      key: 'process-modal-button-submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      id: 'processLevelDatabase-cancel',
      handleClick: onClose,
      key: 'process-modal-button-cancel',
    },
  ];

  const dialogButtonsSelectProcessLevel = [
    {
      buttonStyle: BUTTON_PRIMARY,
      content: t('add'),
      id: 'processLevelDatabase-selection-submit',
      handleClick: () => {
        setLinkedLevel(Number(selectedLinkedLevel));
        setShowSelectProcessDialog(false);
      },
      key: 'select-process-modal-button-submit',
    },
    {
      buttonStyle: BUTTON_SECONDARY,
      content: t('cancel'),
      id: 'processLevelDatabase-selection-cancel',
      handleClick: () => {
        setLinkedLevel(undefined);
        setShowSelectProcessDialog(false);
      },
      key: 'select-process-modal-button-cancel',
    },
  ];

  const renderLevelFields = (): React.ReactNode =>
    processLevelFields?.map(({ Component: Comp, ...fieldProperties }: Field) => {
      const enabled =
        fieldProperties.code === AttributeCode.LEVEL1_PROCESS ? isFirstLinkedLevelEnabled : isSecondLinkedLevelEnabled;
      fieldProperties.disabled = !enabled;
      fieldProperties.required = enabled;
      return (
        <Comp
          {...fieldProperties}
          key={fieldProperties.id}
          onClick={() => handleOnClickParentLevelDialog(fieldProperties.code === AttributeCode.LEVEL1_PROCESS ? 1 : 2)}
        />
      );
    });

  const renderInputs = (fieldsToRender?: Field[]): React.ReactNode =>
    fieldsToRender
      ?.filter((field) => field.code !== AttributeCode.LEVEL1_PROCESS && field.code !== AttributeCode.LEVEL2_PROCESS)
      .map(({ Component, ...fieldProps }: Field) => {
        if (fieldProps.code === AttributeCode.LEVEL) {
          return (
            <section className={styles.ProcessLevelSection} key={fieldProps.id}>
              <Component {...fieldProps} />
              <div>{renderLevelFields()}</div>
            </section>
          );
        }
        if ([AttributeCode.CATEGORY, AttributeCode.COORDINATOR].includes(fieldProps.code))
          fieldProps.disabled = isFirstLinkedLevelEnabled || isSecondLinkedLevelEnabled;

        return <Component {...fieldProps} key={fieldProps.id} />;
      });

  return (
    <>
      <DialogNEPOS
        dialog={{
          buttons: dialogButtonsCreateEditDialog,
          title: !defaultValues ? t('createNewProcess') : t('editAProcess'),
        }}
        extraClass="Modal"
      >
        <section className={styles.Form}>{renderInputs(fields[i18n.language as Language])}</section>
      </DialogNEPOS>
      {showSelectProcessDialog && (
        <DialogNEPOS
          dialog={{
            buttons: dialogButtonsSelectProcessLevel,
            title: t(`requirements.addLevelXProcess`, { number: isFirstLinkedLevelEnabled ? 1 : 2 }),
          }}
        >
          <TableWithSearch<ProcessLevelSelectionTableRow>
            columns={selectProcessLevelDialogColumns}
            id="process_level"
            onRowClick={(id: string) => setSelectedLinkedLevel(id)}
            rows={filteredSelectProcessLevelDialogRows}
            searching={(text: string) => setLinkProcessDialogSearchText(text)}
            selectedRow={selectedLinkedLevel}
            variant={TableVariant.PROCESS_LEVEL_SELECTION}
          />
        </DialogNEPOS>
      )}
    </>
  );
};

export default ProcessLevelFormDialog;
