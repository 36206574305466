import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { STATUS } from 'assets/constants/constants';
import { getDangerouslySetInnerHTML, getFormattedDate, getLanguageAttributes } from 'assets/js/Utils';

import styles from './CatalogObjectRow.module.scss';

const CatalogObjectRow = (props) => {
  const {
    direction,
    element,
    fieldToOrder,
    handleRowClick,
    handleCheckAll,
    isChecked,
    isDisabled,
    isHeader,
    isRadio,
    sortTable,
    isNeposQIGroup,
    isNotSwimlane,
    isNotInputOutput,
    isRoles,
    catalogUsers,
  } = props;
  const { t, i18n } = useTranslation();
  const checked = isChecked ? styles.Checked : '';
  const linkColor = isChecked ? styles.CheckedLink : '';
  const attributes = getLanguageAttributes(
    element.attributes,
    i18n.language.toUpperCase(),
    typeof element.attributes !== 'object',
  );

  const [isContainedInExpanded, setIsContainedInExpanded] = useState(false);

  const getSortedClass = (field) => (fieldToOrder === field ? styles[direction] : '');

  const handleLinkClick = (event) => {
    event.stopPropagation();
  };

  const handleExpandButtonClick = (event) => {
    setIsContainedInExpanded(!isContainedInExpanded);
    event.stopPropagation();
  };

  const getUsersFromCatalog = (userString) => {
    if (!userString || userString === undefined) return '';
    const currentUser = catalogUsers.USER?.find((user) => user.code === userString);

    return currentUser ? currentUser.commonName : '';
  };

  if (isHeader) {
    return (
      <tr className={`${styles.Header} ${checked} ${styles.ListItem}`}>
        <th className={styles.Checkbox}>
          {!isRadio && (isNeposQIGroup || (isNotSwimlane && isNotInputOutput)) && (
            <span className={`${styles.Checkmark} ${isDisabled ? styles.Disabled : ''}`} onClick={handleCheckAll} />
          )}
        </th>
        <th className={`${styles.Name} ${getSortedClass('objectName')}`} onClick={() => sortTable('objectName')}>
          {element.name}
        </th>
        <th
          className={`${styles.Description} ${getSortedClass('objectDescription')}`}
          onClick={() => sortTable('objectDescription')}
        >
          {element.description}
        </th>
        <th className={`${styles.Area} ${getSortedClass('responsibleArea')}`} onClick={() => sortTable('responsibleArea')}>
          {element.area}
        </th>
        <th className={`${styles.ContainedIn} ${styles.NonSortable}`}>{element.usedIn}</th>
        {isRoles && isNotSwimlane && (
          <>
            <th className={`${styles.AuthorProcess} ${styles.NonSortable}`}>{element.authorProcess}</th>
            <th className={`${styles.Requester} ${styles.NonSortable}`}>{element.requester}</th>
            <th className={`${styles.Approver} ${styles.NonSortable}`}>{element.approver}</th>
          </>
        )}
        <th className={`${styles.Created} ${getSortedClass('creationDate')}`} onClick={() => sortTable('creationDate')}>
          {element.created}
        </th>
      </tr>
    );
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  return (
    <tr className={`${styles.ListItem} ${checked}`} key={element.id} onClick={() => handleRowClick(element.id)}>
      <td className={styles.Checkbox}>
        {(isNeposQIGroup || (isNotSwimlane && isNotInputOutput)) && (
          <span className={isRadio ? styles.InputRadio : styles.Checkmark} />
        )}
      </td>
      <td className={styles.Name}>{attributes.OBJECT_NAME}</td>
      {/* eslint-disable-next-line react/no-danger */}
      <td className={styles.Description} dangerouslySetInnerHTML={getDangerouslySetInnerHTML(attributes.OBJECT_DESCRIPTION)} />
      <td className={styles.Area}>{attributes.RESPONSIBLE_AREA}</td>
      <td className={styles.ContainedIn}>
        <div className={styles.UsedIn}>
          {element.diagramIdentifierList?.length > 0 ? (
            <>
              {`${element.diagramIdentifierList.length} ${t('nameAttributes.tables.diagrams')}`}
              <button className={styles.ExpandButton} onClick={handleExpandButtonClick} type="button">
                {isContainedInExpanded ? t('hide') : t('show')}
              </button>
            </>
          ) : (
            ''
          )}
        </div>
        {isContainedInExpanded && (
          <div className={styles.InnerList}>
            {element.diagramIdentifierList?.map((item) => (
              <a
                className={`${styles.InnerListElement} ${styles.Link} ${linkColor}`}
                href={item.status === STATUS.SANDBOX ? `/sandbox/${item.id}` : `/diagram/${item.id}`}
                onClick={handleLinkClick}
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="di icon-link" />
                {/* TODO: Remove JSON.parse whenever backend is already sending objects and not a mix of strings and objects */}
                <span className={styles.InnerListElementText}>
                  {
                    (typeof item.nameMap === 'object' ? item.nameMap : JSON.parse(item.nameMap))[i18n.language.toUpperCase()]
                      .PROCESS_NAME
                  }
                </span>
              </a>
            ))}
          </div>
        )}
      </td>
      {isRoles && isNotSwimlane && (
        <>
          <td className={` ${styles.AuthorProcess}`}>
            {isContainedInExpanded && (
              <div className={`${styles.InnerList} ${styles.AuthorList}`}>
                {element.diagramIdentifierList?.map((item) => (
                  <div className={`${styles.InnerListElement} ${styles.Author}`}>
                    <span className={styles.InnerListElementText}>{getUsersFromCatalog(item.author)}</span>
                  </div>
                ))}
              </div>
            )}
          </td>
          <td className={styles.Requester}>{getUsersFromCatalog(attributes.REQUESTER)}</td>
          <td className={styles.Approver}>{getUsersFromCatalog(attributes.APPROVER)}</td>
        </>
      )}
      {/* TODO: Remove CREATION attribute whenever backend already removed it in order to standarize CREATION_DATE in all services */}
      <td className={styles.Created}>{getFormattedDate(attributes.CREATION_DATE || attributes.CREATION)}</td>
    </tr>
  );
};

export default CatalogObjectRow;
