import { Link } from 'react-router-dom';

import Button from '../Button/Button';
import styles from './AdminCard.module.scss';

const AdminCard = ({ card }) => {
  return (
    <>
      {card.isButton ? (
        <div className={`${styles.Card} ${styles.Button}`}>
          <Button
            btnText={card.title}
            buttonStyle="Primary"
            custom="custom"
            handleClick={card.handleClick}
            icon={card.icon}
            id={card.id}
            isLoading={card.isLoading}
          />
        </div>
      ) : (
        <Link
          className={styles.Link}
          id={card.id}
          to={{ pathname: card.url, state: { tabSelected: card.tabSelected, isLevel3: card.isLevel3 } }}
        >
          <div className={styles.Card}>
            <i className={card.icon} />
            <h3>{card.title}</h3>
          </div>
        </Link>
      )}
    </>
  );
};

export default AdminCard;
