import React, { useEffect } from 'react';

import i18next from 'i18next';

import TitleBar from 'components/TitleBar/TitleBar';
import titleService from 'services/titleService';

import maintenanceImg from '../../assets/images/transfer.gif';
import styles from './Maintenance.module.scss';

const Maintenance = () => {
  useEffect(() => {
    titleService.updatePageTitle(i18next.t('maintenance.pageTitle'));
  }, []);

  return (
    <>
      <TitleBar blank />
      <div className={styles.Content}>
        <div className={styles.Administration}>
          <section className={styles.Text}>
            <h1>{i18next.t('maintenance.title')}</h1>
            <p>{i18next.t('maintenance.message')}</p>
          </section>
          <section className={styles.Img}>
            <img alt="Maintenance animation" src={maintenanceImg} />
          </section>
        </div>
      </div>
    </>
  );
};

export default Maintenance;
