import { useState, useEffect } from 'react';

import { SwitchComponent } from '@syncfusion/ej2-react-buttons';

import styles from './RecommendationsRow.module.scss';

const RecommendationsRow = ({
  isHeader,
  element,
  handleDownloadClick,
  showHistoryDialog,
  switchUpdate,
  sortTable,
  direction,
  fieldToOrder,
}) => {
  const [switchStatus, setSwitchStatus] = useState(element.update);
  const [rows, setRows] = useState([]);

  const handleSwitchChange = () => {
    setSwitchStatus();
    switchUpdate();
  };

  const mapRows = () => {
    const rowsList = element.locations.map((elem) => (
      <tr className={`${styles.ListItem} ${styles.Recommendation}`} key={elem.id}>
        <td className={styles.Locations}>{elem.location}</td>
        <td className={styles.RecommendedOn}>{elem.recommendedOn}</td>
        <td className={styles.RecommendedBy}>{elem.recommendedBy}</td>
        <td className={styles.Status}>{elem.status}</td>
        <td className={styles.Feedback}>{elem.feedback}</td>
        <td className={styles.Gaplog}>{elem.gaplog}</td>
        <td className={styles.Report}>
          {elem.isPublished && (
            <i
              className="di icon-herunterladen"
              id={`download-${element.name}-${elem.locationCode}-${element.processNumber}`}
              onClick={() => handleDownloadClick({ ...elem, name: element.name, processNumber: element.processNumber })}
            />
          )}
        </td>
        <td className={styles.History}>
          <i className="di icon-uhr-zeit" onClick={() => showHistoryDialog({ ...elem, processNumber: element.processNumber })} />
        </td>
        <td className={styles.Update}>
          <SwitchComponent change={handleSwitchChange} checked={elem.update} cssClass={styles.Switch} />
          <span className={styles.SwitchStatus}>{switchStatus}</span>
        </td>
      </tr>
    ));

    return rowsList;
  };

  useEffect(() => {
    setRows([]);
  }, [element]);

  useEffect(() => {
    if (rows.length > 0) {
      const rowsList = mapRows();
      setRows(rowsList);
    }
  }, [direction]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSortedClass = (field) => {
    return fieldToOrder === field ? styles[direction] : '';
  };

  if (isHeader) {
    return (
      <tr className={`${styles.Header} ${styles.ListItem} ${styles.Recommendation}`}>
        <th className={`${styles.Locations} ${getSortedClass('location')}`} onClick={() => sortTable('location')}>
          {element.locations}
        </th>
        <th className={`${styles.RecommendedOn} ${getSortedClass('recommendedOn')}`} onClick={() => sortTable('recommendedOn')}>
          {element.recommendedOn}
        </th>
        <th className={`${styles.RecommendedBy} ${getSortedClass('recommendedBy')}`} onClick={() => sortTable('recommendedBy')}>
          {element.recommendedBy}
        </th>
        <th className={`${styles.Status} ${getSortedClass('status')}`} onClick={() => sortTable('status')}>
          {element.status}
        </th>
        <th className={`${styles.Feedback} ${getSortedClass('feedback')}`} onClick={() => sortTable('feedback')}>
          {element.feedback}
        </th>
        <th className={`${styles.Gaplog} ${getSortedClass('gaplog')}`} onClick={() => sortTable('gaplog')}>
          {element.gaplog}
        </th>
        <th className={styles.Report}>{element.report}</th>
        <th className={styles.History}>{element.history}</th>
        <th className={styles.Update}>{element.update}</th>
      </tr>
    );
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  const onClickRow = () => {
    let rowsList = [];

    if (rows.length === 0) {
      rowsList = mapRows();
    }

    setRows(rowsList);
  };

  return (
    <>
      <tr className={`${styles.ListItem} ${styles.Recommendation} ${styles.CollapsableRow}`} onClick={onClickRow}>
        <td className={styles.Name}>
          <i className={`di icon-pfeil-chevron-rechts ${rows.length !== 0 ? styles.Expanded : ''}`} />
          {element.name}
        </td>
      </tr>
      {rows}
    </>
  );
};

export default RecommendationsRow;
