import React from 'react';

import { useTranslation } from 'react-i18next';

import * as Constants from '../../../../assets/constants/constants';
import * as Config from '../../../../pages/MyTasks/Config';
import styles from './MyTasksRow.module.scss';

const MyTasksRow = (props) => {
  const {
    direction,
    element,
    fieldToOrder,
    handleDownloadClick,
    handleRowClick,
    isHeader,
    selectedTab,
    showRecommendationColumns,
    sortTable,
  } = props;
  const { t } = useTranslation();
  const showRecommendationFields = showRecommendationColumns && element.taskType?.code.includes(Config.RECOMMENDED);
  const recommendedStyle = element.taskType?.code.includes(Config.RECOMMENDED) ? styles.RecommendedTask : '';
  const isClosedTask = Constants.CLOSED_TASK_TYPES.includes(element.status);
  const tasksStyle = selectedTab === Constants.TASK_GROUPS.GROUP_TASKS ? styles.GroupTasks : styles.ClosedTasks;
  let dateColumns = '';
  let recommendationColumns = '';

  const getSortedClass = (field) => (fieldToOrder === field ? styles[direction] : '');

  const onClickDownload = (event) => {
    event.stopPropagation();
    handleDownloadClick(element);
  };

  if (isHeader) {
    dateColumns =
      selectedTab === Constants.TASK_GROUPS.CLOSED_TASKS ? (
        <th
          className={`${styles.CloseDate} ${getSortedClass('closeDate')}`}
          id="my-tasks-header-close-date"
          onClick={() => sortTable('closeDate')}
        >
          {element.closeDate}
        </th>
      ) : (
        <>
          <th
            className={`${styles.StartDateTask} ${getSortedClass('creationDate')}`}
            id="my-tasks-header-creation-date"
            onClick={() => sortTable('creationDate')}
          >
            {element.startDate}
          </th>
          <th
            className={`${styles.Deadline} ${getSortedClass('deadline')}`}
            id="my-tasks-header-deadline"
            onClick={() => sortTable('deadline')}
          >
            {element.deadline}
          </th>
        </>
      );

    recommendationColumns = showRecommendationColumns && (
      <>
        <th className={`${styles.Link} ${getSortedClass('link')}`} id="my-tasks-header-link" onClick={() => sortTable('link')}>
          {element.link}
        </th>
        <th className={styles.Download}>{}</th>
      </>
    );
  } else {
    dateColumns = isClosedTask ? (
      <td className={styles.CloseDate}>{element.closeDate !== 'null' ? element.closeDate : ''}</td>
    ) : (
      <>
        <td className={styles.StartDateTask}>{element.startDate}</td>
        <td className={styles.Deadline}>{element.deadline !== 'null' ? element.deadline : ''}</td>
      </>
    );

    recommendationColumns = showRecommendationFields ? (
      <>
        <td className={styles.Link}>
          <span
            className={Config.RECOMMENDATION_LINK}
            onClick={(event) => !event.target.className && handleRowClick(element, event)}
          >
            <i className="di icon-link" />
            {t('tasks.recommendedProcess')}
          </span>
        </td>
        <td className={styles.Download}>
          <i className="di icon-herunterladen" onClick={onClickDownload} />
        </td>
      </>
    ) : (
      <>
        <td className={styles.Link}>{}</td>
        <td className={styles.Download}>{}</td>
      </>
    );
  }

  if (isHeader) {
    return (
      <tr
        className={`${styles.Header} ${styles.ListItem} ${styles.Task} ${tasksStyle} ${
          showRecommendationColumns ? styles.Recommended : ''
        }`}
      >
        <th
          className={`${styles.Name} ${
            selectedTab === Constants.TASK_GROUPS.CLOSED_TASKS ? styles.ClosedTasksTab : ''
          } ${getSortedClass('title')}`}
          id="my-tasks-header-title"
          onClick={() => sortTable('title')}
        >
          {element.name}
        </th>
        <th
          className={`${styles.Author} ${getSortedClass('author')}`}
          id="my-tasks-header-author"
          onClick={() => sortTable('author')}
        >
          {element.userCodeCreator}
        </th>
        <th
          className={`${styles.TypeTask} ${getSortedClass('tasks')}`}
          id="my-tasks-header-task"
          onClick={() => sortTable('tasks')}
        >
          {element.typeTask}
        </th>
        {dateColumns}
        {recommendationColumns}
      </tr>
    );
  }

  if (typeof element === 'string') {
    return (
      <tr className={styles.ListItem}>
        <td className={styles.Message}>{element}</td>
      </tr>
    );
  }

  return (
    <tr
      className={`${styles.ListItem} ${styles.Task} ${recommendedStyle} ${tasksStyle} ${
        showRecommendationColumns ? styles.Recommended : ''
      } ${styles[element.status]}`}
      onClick={(event) => event.target.className && handleRowClick(element, event)}
    >
      <td className={`${styles.Name} ${isClosedTask ? styles.ClosedTasksTab : ''}`}>{element.name}</td>
      <td className={styles.Author}>{element.author}</td>
      <td className={styles.TypeTask}>{t(`tasks.type.${element.type}`)}</td>
      {dateColumns}
      {recommendationColumns}
    </tr>
  );
};

export default MyTasksRow;
