import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import * as Constants from '../../assets/constants/constants';
import * as Utils from '../../assets/js/Utils';
import DialogObjectCatalog from '../../components/DialogObjectCatalog/DialogObjectCatalog';
import TitleBar from '../../components/TitleBar/TitleBar';
import * as Dialog from '../../components/UI/Dialogs/Dialogs';
import Pagination from '../../components/UI/Pagination/Pagination';
import Search from '../../components/UI/Search/Search';
import MyTasksRow from '../../components/UI/TableList/MyTasksRow/MyTasksRow';
import TableList from '../../components/UI/TableList/TableList';
import useAuth from '../../hooks/useAuth';
import dashboardServices from '../../services/dashboardServices';
import documentationService from '../../services/documentationService';
import titleService from '../../services/titleService';
import * as Config from './Config';
import styles from './MyTasks.module.scss';

const MyTasks = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { checkPermission } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [disableClick, setDisableClick] = useState(false);
  const [results, setResults] = useState(null);
  const [diagramStatus, setDiagramStatus] = useState(null);
  const [totalTaskCount, setTotalTaskCount] = useState();
  const [tabSelected, setTabSelected] = useState(location.state?.tabSelected || Constants.TASK_GROUPS.MY_TASKS);
  const [sorting, setSorting] = useState({ filter: '', order: Constants.SORT_ASC });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [searchText, setSearchText] = useState('');
  const [taskSelected, setTaskSelected] = useState();
  const [isManageObjectDialogVisible, setIsManageObjectDialogVisible] = useState(false);
  const [objectTask, setObjectTask] = useState({});
  const [objectType, setObjectType] = useState('');
  const [users, setUsers] = useState([]);
  const [catalogUsers, setCatalogUsers] = useState([]);
  const confirmDialog = useRef(null);

  const fetchResults = () => {
    setIsLoading(true);
    dashboardServices
      .getTasksByGroup({
        group: tabSelected,
        page: page - 1,
        ...(searchText ? { search: searchText } : {}),
        size: Constants.DEFAULT_PAGE_SIZE,
        ...(sorting.filter ? sorting : {}),
      })
      .then((response) => {
        const newResults = response.data.results.map((task) => ({
          ...task,
          name: JSON.parse(task.title)[i18n.language.toUpperCase()].TITLE,
          author: task.userCodeCreator || '-',
          startDate: Utils.getFormattedDate(task.creationDate),
          deadline: Utils.getFormattedDate(task.finalDate),
          closeDate: Utils.getFormattedDate(task.lastModificationDate),
        }));
        const newDiagramStatus = response.data.diagramStatus;
        setResults(newResults);
        setDiagramStatus(newDiagramStatus);
        setTotalTaskCount({
          [Constants.TASK_GROUPS.MY_TASKS]: response.data.myTasks,
          [Constants.TASK_GROUPS.GROUP_TASKS]: response.data.groupTasks,
          [Constants.TASK_GROUPS.CLOSED_TASKS]: response.data.closedTasks,
        });
        setTotalPages(response.data.numberOfPages);
        setUsers(response.data.allUsers);
        setIsLoading(false);
      })
      .catch(dashboardServices.handleServiceError);
  };

  useEffect(() => {
    titleService.updatePageTitle(t(`tasks.${tabSelected}`));
    fetchResults();
  }, [page, searchText, sorting.filter, sorting.order, tabSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortTable = (filter) => {
    if (sorting.filter !== filter) setSorting({ filter, order: Constants.SORT_ASC });
    else if (sorting.order === Constants.SORT_ASC) setSorting({ ...sorting, order: Constants.SORT_DESC });
    else setSorting({ ...sorting, order: Constants.SORT_ASC });
    setPage(1);
  };

  const handleDownloadClick = ({ name, processNumber, version }) => {
    documentationService
      .getDocument(processNumber, version, Constants.ENGLISH, Constants.EXCHANGE)
      .then((res) => {
        const fileName = `${name}.pdf`;
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        Utils.downloadFile(url, fileName, res.headers['content-disposition']);
      })
      .catch((err) => {
        documentationService.handleServiceError(err);
      });
  };

  const showConfirmDialog = (url) => {
    const confirmDialogData = {
      title: t('warningText'),
      message: t('recommendations.view.linkDialog.content'),
      textCancel: t('cancel'),
      textOk: t('confirm'),
      okClick: () => {
        window.location.href = url;
      },
    };

    confirmDialog.current = Dialog.showConfirm(confirmDialogData);
  };

  const onSearch = (newSearchText) => {
    setSearchText(newSearchText);
    setPage(1);
  };

  const redirectToDiagram = (taskLink) => {
    if (taskLink) {
      const diagramID = taskLink.split('/').pop();
      const diagramStatusType = diagramStatus[taskLink];
      const target = diagramStatusType ? Constants.DIAGRAM_STATUS_REDIRECT[diagramStatusType] : undefined;
      if (diagramStatusType && target) history.push(`/${target}/${diagramID}`);
    }
  };

  const redirectToDataset = (task) => {
    if (task) {
      history.push(`/dataset/${task.link}`);
    }
  };

  const handleRowClick = (task, event) => {
    if (disableClick) return;

    setTaskSelected(task);
    const { type: taskTypeCode, status: taskStatusCode } = task;
    if (taskTypeCode === Constants.BLOCKED) return;

    const isClosedTask = tabSelected === Constants.TASK_GROUPS.CLOSED_TASKS;
    if (isClosedTask) {
      if (Utils.isTaskValidClosedWorkflow(task)) {
        return history.push(`/closed-workflow/${task.idWorkflow}`);
      }

      return history.push(`/diagram/${task.link}`);
    }

    const isNotClickable =
      event.target.className !== Config.RECOMMENDATION_LINK &&
      Constants.DISABLED_TASK_TYPES.some((type) => [taskStatusCode, taskTypeCode].includes(type));
    if (isNotClickable) return;

    if (taskTypeCode && taskTypeCode.includes(Config.RECOMMENDED)) {
      if (event.target.className === Config.RECOMMENDATION_LINK) {
        showConfirmDialog(task.url);
      } else {
        history.push(`/recommendation/${task.link}`);
      }
    } else if (
      taskStatusCode === Constants.DONE &&
      (taskTypeCode === Config.WORKFLOW_APPROVAL || taskTypeCode === Config.WORKFLOW_APPROVAL_GROUP)
    ) {
      history.push(`/diagram/${task.link}`);
    } else if (
      taskTypeCode === Config.DESIGN_DIAGRAM ||
      taskTypeCode === Config.WORKFLOW_APPROVAL ||
      taskTypeCode === Config.DATASET_WORKFLOW_APPROVAL ||
      (taskStatusCode === Constants.ACCEPTED && taskTypeCode === Config.WORKFLOW_APPROVAL_GROUP) ||
      (taskStatusCode === Constants.ACCEPTED && taskTypeCode === Config.DATASET_WORKFLOW_APPROVAL_GROUP)
    ) {
      return taskTypeCode === Config.DATASET_WORKFLOW_APPROVAL || taskTypeCode === Config.DATASET_WORKFLOW_APPROVAL_GROUP
        ? redirectToDataset(task)
        : redirectToDiagram(task.link);
    } else if (taskTypeCode === Config.REQUIREMENT_REQUEST || taskTypeCode === Config.REQUIREMENT_WORKFLOW) {
      history.push(`/requirements/${task.link}`);
    } else {
      setDisableClick(true);
      Config.acceptTask(task, results)
        .then((response) => {
          setCatalogUsers(response.infoTask?.catalog);
          if (response) {
            if (
              taskTypeCode === Config.DESIGN_IT_SYSTEM ||
              taskTypeCode === Config.DESIGN_ROLE ||
              taskTypeCode === Config.DESIGN_IO_SIPOC ||
              taskTypeCode === Config.DESIGN_IT_SYSTEM_SWIMLANE ||
              taskTypeCode === Config.DESIGN_ROLE_SWIMLANE
            ) {
              setObjectTask({
                ...response.infoTask,
                requestedByCommonName:
                  taskTypeCode === Config.DESIGN_IO_SIPOC ||
                  taskTypeCode === Config.DESIGN_IT_SYSTEM_SWIMLANE ||
                  taskTypeCode === Config.DESIGN_ROLE_SWIMLANE
                    ? users.find((user) => user.code === response.infoTask.attributes.NOT_TRANSLATABLE.REQUESTER).commonName
                    : users.find((user) => user.code === response.infoTask.requestedBy).commonName,
              });

              if (response.isItSystem) {
                setObjectType(taskTypeCode === Config.DESIGN_IT_SYSTEM ? Constants.IT_SYSTEM : Constants.IT_SYSTEM_SWIMLANE);
              } else {
                switch (taskTypeCode) {
                  case Config.DESIGN_ROLE:
                    setObjectType(Constants.ROLE);
                    break;
                  case Config.DESIGN_ROLE_SWIMLANE:
                    setObjectType(Constants.ROLE_SWIMLANE);
                    break;
                  default:
                    setObjectType(Constants.IO_SIPOC);
                }
              }
              setIsManageObjectDialogVisible(true);
              setTaskSelected(response.taskSelected);
            } else {
              return taskTypeCode.includes(Config.DATASET_WORKFLOW_APPROVAL)
                ? redirectToDataset(task)
                : redirectToDiagram(task.link);
            }
          }
        })
        .finally(() => {
          setDisableClick(false);
        });
    }
  };

  return (
    <>
      <TitleBar />
      <div className={`${styles.Content} ${styles.NoToolbar}`}>
        <div className={styles.SearchSection}>
          <Search disabled={isLoading} isAsync searching={onSearch} />
        </div>
        <div className={styles.MyTasksHeader} id="tasksHeader">
          <div className={styles.TaskTabs}>
            {Object.values(Constants.TASK_GROUPS).map((group) => {
              return (
                <div
                  className={`${styles.TaskTab} ${group === tabSelected ? styles.TabSelected : ''}`}
                  id={`my-tasks-tab-${group}`}
                  key={group}
                  onClick={() => {
                    setTabSelected(group);
                    setPage(1);
                  }}
                >
                  <span>{t(`tasks.${group}`)}</span>
                  <span className={styles.TaskNumber}>{totalTaskCount?.[group] || '-'}</span>
                </div>
              );
            })}
          </div>
        </div>
        <TableList
          direction={sorting.order}
          fieldToOrder={sorting.filter}
          handleDownloadClick={handleDownloadClick}
          handleRowClick={handleRowClick}
          header={{
            name: t('tasks.name'),
            userCodeCreator: t('tasks.userCodeCreator'),
            typeTask: t('tasks.typeTask'),
            startDate: t('tasks.startDateTask'),
            deadline: t('tasks.deadline'),
            closeDate: t('tasks.closeDate'),
            link: t('tasks.link'),
          }}
          isLoading={isLoading}
          list={results}
          RowComponent={MyTasksRow}
          selectedTab={tabSelected}
          showRecommendationColumns={checkPermission('taskView') && tabSelected !== Constants.TASK_GROUPS.GROUP_TASKS}
          sortTable={sortTable}
        />
        {totalPages > 1 && (
          <div className={styles.PagesSection}>
            <Pagination page={page} pageClick={setPage} totalPages={totalPages} />
          </div>
        )}
      </div>
      {isManageObjectDialogVisible && (
        <DialogObjectCatalog
          catalogUsers={catalogUsers}
          close={() => setIsManageObjectDialogVisible(false)}
          dialogType={Constants.CATALOG_OBJECT_ACTIONS.MANAGE}
          idTask={taskSelected.id}
          isLegacy={!Constants.NEPOS_OBJECTS.includes(objectType)}
          object={objectTask}
          objectType={objectType}
          onSubmit={fetchResults}
        />
      )}
      <div id="dialog" />
    </>
  );
};

export default MyTasks;
